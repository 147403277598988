import { computed, makeObservable, observable } from 'mobx';
import { Collaborator, Permission } from 'shared';

import Model, { ModelError } from '@models/base/base.model';

import { PageStore } from '@stores/base/page.store';
import { ProcessStore } from '@stores/process.store';

import { newError } from '@/services/errors/errors';
import { ProcessImageTag } from '@/types/process.types';

import { DatabaseModel } from './database.model';
import { GenerationModel } from './generation.model';
import { WorkflowModel } from './workflow.model';

export class ProcessModel extends Model {
  private name: string;
  private icon: string;
  private published: boolean;
  private permission: Permission;
  private collaborators: Collaborator[];
  private is_public: boolean;
  private updatedAt: string;
  private createdAt: string;
  private image: string;
  private draft: boolean;
  private iconUrl: string =
    'https://storage.googleapis.com/speaknact_prod/users/avatars/avatar_1627425719_652.png';
  private deletedAt?: string;
  private password?: string;

  private isSmallForm: boolean;

  /* ------------------------ Store-related properties ------------------------ */
  private workflowIds: string[];
  private databaseIds: string[];
  private transitionIds: string[];
  private notificationTemplateIds: string[];
  private generationsPageStore = new PageStore<GenerationModel>({
    totalNumberOfItemsInDB: 0,
    itemsPerPage: 7,
    baseStore: this.store.rootStore.generationStore
  });

  constructor(
    store: ProcessStore,
    id: Model['id'],
    name: string,
    isSmall: boolean,
    icon: string,
    published: boolean,
    permission: Permission,
    collaborators: Collaborator[],
    is_public: boolean,
    updatedAt: string,
    createdAt: string,
    image: string,
    draft: boolean,
    password?: string,
    iconUrl?: string,
    deletedAt?: string
  ) {
    super(store, id, false);

    this.name = name;
    this.icon = icon;
    this.published = published;
    this.permission = permission;
    this.collaborators = collaborators;
    this.is_public = is_public;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.image = image;
    this.draft = draft;
    this.password = password;
    this.deletedAt = deletedAt;
    this.isSmallForm = isSmall;

    iconUrl && (this.iconUrl = iconUrl);

    this.workflowIds = [];
    this.databaseIds = [];
    this.transitionIds = [];
    this.notificationTemplateIds = [];

    makeObservable<
      ProcessModel,
      | 'name'
      | 'icon'
      | 'published'
      | 'permission'
      | 'collaborators'
      | 'is_public'
      | 'updatedAt'
      | 'image'
      | 'draft'
      | 'password'
      | 'iconUrl'
      | 'deletedAt'
    >(this, {
      name: observable,
      icon: observable,
      published: observable,
      permission: observable,
      collaborators: observable,
      is_public: observable,
      updatedAt: observable,
      image: observable,
      draft: observable,
      password: observable,
      iconUrl: observable,
      deletedAt: observable,
      workflows: computed
    });
  }

  /* ------------------------ Class properties getters ------------------------ */
  getName() {
    return this.name;
  }

  isPublished() {
    return this.published;
  }

  getPermission() {
    return this.permission;
  }

  getCollaborators() {
    return this.collaborators;
  }

  getIsPublic() {
    return this.is_public;
  }

  getUpdatedAt() {
    return this.updatedAt;
  }

  getCreatedAt() {
    return this.createdAt;
  }

  getIconUrl() {
    return this.iconUrl;
  }

  getPassword() {
    return this.password;
  }

  getImage() {
    return this.image;
  }

  isDraft() {
    return this.draft;
  }

  getWorkflowIds() {
    return this.workflowIds;
  }

  getDatabaseIds() {
    return this.databaseIds;
  }

  getTransitionIds() {
    return this.transitionIds;
  }

  getNotificationTemplateIds() {
    return this.notificationTemplateIds;
  }

  isSmall() {
    return this.isSmallForm;
  }

  /* ------------------------ Class properties setters ------------------------ */
  updateCollaborator(newCollaboratorId: string, permissions: Permission) {
    const foundCollaborator = this.collaborators.find(
      (collaborator) => collaborator.id === newCollaboratorId
    );
    if (!foundCollaborator) return;
    foundCollaborator.permissions = permissions;
  }

  setCollaborators(newCollaborators: Collaborator[]) {
    this.collaborators = newCollaborators;
  }

  setPassword(newPassword: string) {
    this.password = newPassword;
  }

  setIsPublic(newIsPublic: boolean) {
    this.is_public = newIsPublic;
  }

  setName(newName: string) {
    this.name = newName;
  }

  setImage(newImage: string) {
    this.image = newImage;
  }

  /* ----------------------------- Custom Getters ----------------------------- */
  get imageUrl() {
    return this.image?.split(':').slice(0, -1).join(':');
  }

  get imageTag() {
    const tag = this.image?.split(':').at(-1);
    if (!tag) return undefined;
    if (!Object.values(ProcessImageTag).includes(tag as ProcessImageTag))
      return undefined;
    return tag as ProcessImageTag;
  }

  get workflows(): WorkflowModel[] {
    const workflows: WorkflowModel[] = [];
    for (const workflowId of this.workflowIds) {
      const workflow = this.store.rootStore.workflowStore.get(workflowId);
      if (!workflow) {
        newError(
          'PROCM-lyQLF',
          `Workflow ${workflowId} not found while getting workflows for process ${this.id}, probably deleted`
        );
        continue;
      }
      workflows.push(workflow);
    }
    return workflows;
  }

  get databases(): DatabaseModel[] {
    const databases: DatabaseModel[] = [];
    for (const databaseId of this.databaseIds) {
      const database = this.store.rootStore.databaseStore.get(databaseId);
      if (!database) {
        newError(
          'PROCM-pVx19',
          `Database ${databaseId} not found while getting databases for process ${this.id}, probably deleted`
        );
        continue;
      }
      databases.push(database);
    }
    return databases;
  }

  get generationPageStore(): PageStore<GenerationModel> {
    return this.generationsPageStore;
  }

  /* ----------------------------- Custom Setters ----------------------------- */
  set imageTag(newTag: ProcessImageTag | undefined) {
    if (!newTag) {
      return;
    }
    this.image = this.imageUrl?.concat(':').concat(newTag);
  }

  setWorkflowIds(newWorkflowIds: string[]) {
    this.workflowIds = newWorkflowIds;
  }

  setDatabaseIds(newDatabaseIds: string[]) {
    this.databaseIds = newDatabaseIds;
  }

  setTransitionIds(newTransitionIds: string[]) {
    this.transitionIds = newTransitionIds;
  }

  setNotificationTemplateIds(newNotificationTemplateIds: string[]) {
    this.notificationTemplateIds = newNotificationTemplateIds;
  }

  /* ----------------------------- Abstract methods ----------------------------- */
  get toJSON() {
    return {
      name: this.name,
      is_public: this.is_public,
      password: this.password,
      image: this.image
    };
  }

  get errors(): ModelError[] {
    return [];
  }
}
