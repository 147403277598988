import { CustomCellEditorProps } from 'ag-grid-react';
import { AtomReference, RowData } from 'shared';

import { MultiSelect } from '@components/database/multi-select';

import { DatabaseModel } from '@models/database.model';

import { AtomModel } from '@/mobx/models/atom.model';
import { newError } from '@/services/errors/errors';

type AgGridMultiSelectEditorProps = CustomCellEditorProps<
  RowData,
  AtomReference[]
> & {
  databaseReferenced: DatabaseModel;
};

export const AgGridMultiSelectEditor = ({
  value,
  data,
  onValueChange,
  databaseReferenced
}: AgGridMultiSelectEditorProps) => {
  const _rowAtomId = data.rowAtomId;

  const primaryKeyColumnOfDTRReferenced =
    databaseReferenced.getPrimaryKeyColumn();

  if (!primaryKeyColumnOfDTRReferenced) {
    newError(
      'AGMSE-5gAqW',
      `Primary key column of DTR ${databaseReferenced.id} 
        not found in Multi select editor, 
        this column will not be rendered`
    );
    return null;
  }

  const rowAtomsOfDTRReferenced: AtomModel<RowData>[] =
    databaseReferenced.getRowAtoms();

  const selectorOptions = rowAtomsOfDTRReferenced.map((rowAtom) => ({
    label:
      rowAtom.data[primaryKeyColumnOfDTRReferenced.field]?.toString() ?? '',
    value: rowAtom.id
  }));

  const onStringValuesChange = (values: string[]) => {
    const correspondingRowAtoms = rowAtomsOfDTRReferenced.filter((rowAtom) =>
      values.includes(rowAtom.id)
    );

    const correspondingReferences: AtomReference[] = correspondingRowAtoms.map(
      (rowAtom) => ({
        dataItemId: rowAtom.id,
        blockType: 'Row',
        sourceId: databaseReferenced.id
      })
    );

    onValueChange(correspondingReferences);
  };

  return (
    <MultiSelect
      options={selectorOptions}
      onValueChange={onStringValuesChange}
      defaultValue={value?.map((value) => value.dataItemId)}
      variant="pastelBlue"
      maxCount={1}
      className="max-h-full"
    />
  );
};
