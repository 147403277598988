import { useEffect } from 'react';

import { GitCommitHorizontal, Loader2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GenerationStatus } from 'shared';

import { CancelProgress } from '@components/progress/cancelProgress';
import { DoneProgress } from '@components/progress/doneProgress';
import { TodoProgress } from '@components/progress/todoProgress';
import { Avatar, AvatarImage } from '@components/ui/avatar';
import { Badge } from '@components/ui/badge';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from '@components/ui/pagination';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { GenerationModel } from '@models/generation.model';

import { ENV, ENV_TO_READABLE_NAME } from '@/utils/constants';
import { CircularProgress } from '@mui/joy';

import SettingHeader from '../SettingHeader';
import { SettingsContent } from '../settings.style';
import { SettingBody } from '../style';
import { GenerationContainer } from './generation.style';

export const generationStatusToProgress = (
  status: GenerationStatus,
  size = 14
) => {
  switch (status) {
    case GenerationStatus.RUNNING:
      return <Loader2 className="h-4 w-4 animate-spin" color="#4b35d2" />;
    case GenerationStatus.FAILED:
      return <CancelProgress size={size} color="#eb5757" />;
    case GenerationStatus.READY:
      return <DoneProgress size={size} color="#28a745" />;
    case GenerationStatus.PENDING:
      return <TodoProgress size={size} />;
    case GenerationStatus.TIMEOUT:
      return <CancelProgress size={size} color="#f2bf00" />;
    default:
      return <TodoProgress size={size} />;
  }
};

export const POLL_INTERVAL = 5000; // 5 seconds
export const MAX_NAME_LENGTH = 10;

const Generation = () => {
  const process = useProcess();
  const [searchParams, setSearchParams] = useSearchParams();

  const { generationStore, processStore } = useStores();
  const navigate = useNavigate();
  const MAX_NAME_LENGTH = 10;

  const computeCurrentPage = () => {
    const currentPageParam = searchParams.get('page');
    if (!currentPageParam) {
      return 1;
    }

    const page = parseInt(currentPageParam);
    if (
      page < 1 ||
      page > (process?.generationPageStore.getTotalNumberOfPages() ?? 0)
    ) {
      return 1;
    }
    return page;
  };

  const currentPage = computeCurrentPage();

  let generations: GenerationModel[] = [];
  useEffect(() => {
    const intervals: NodeJS.Timeout[] = [];

    const generationsToPoll = generationStore
      .toArray()
      .filter((generation) => generation.shouldBePolled);

    for (const generation of generationsToPoll) {
      const interval = setInterval(() => {
        void generationStore
          .fetchAndParseGeneration(generation.id)
          .then((parsedGeneration) => {
            if (!parsedGeneration) {
              return;
            }

            generation.setPods(parsedGeneration.pods);

            if (!generation.hasFinished) {
              return;
            }

            generation.setCurrentlyDeployed(generation.hasSucceeded);
            generationStore.computeCurrentlyDeployedGeneration();

            clearInterval(interval);
          });
      }, POLL_INTERVAL);

      intervals.push(interval);
    }

    return () => {
      intervals.forEach(clearInterval);
    };
  }, [generations, generationStore]);

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  if (!process.generationPageStore.isPageCached(currentPage)) {
    void processStore.fetchGenerationsForProcess(process, currentPage);
  }

  generations = process.generationPageStore.getPageItems(currentPage);

  const numberOfPages =
    process.generationPageStore.getTotalNumberOfPages() ?? 0;

  return (
    <SettingsContent>
      <SettingHeader
        title="Generations"
        description="See previous and ongoing generations"
      />
      <SettingBody>
        <div className="min-h-116">
          {processStore.isGenerationPageFetching(process.id, currentPage) && (
            <LoaderBox>
              <CircularProgress />
            </LoaderBox>
          )}

          {!processStore.isGenerationPageFetching(process.id, currentPage) &&
            generations?.map((generation, index) => (
              <a
                key={index}
                className="hover:cursor-pointer"
                onClick={() => navigate(generation.id)}
              >
                <GenerationContainer
                  className="text-zinc-500"
                  isFirst={index === 0}
                  isLast={index === generations.length - 1}
                >
                  <div className="flex-1">
                    <div className="font-medium text-zinc-950">
                      {generation.id}
                    </div>
                    <div className="flex flex-row items-center">
                      {ENV_TO_READABLE_NAME[ENV]}
                      {generation.getCurrentlyDeployed() && (
                        <Badge
                          className="ml-2 font-normal"
                          variant={'pastelBlue'}
                        >
                          Current
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div className="basis-36 grow-0 shrink-0">
                    <div className="flex flex-row items-center">
                      {generationStatusToProgress(generation.status)}
                      <div className="ml-2.5 font-medium">
                        {generation.status}
                      </div>
                    </div>
                    <div className="pl-6">
                      {generation.duration} ({generation.elapsedTime})
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="flex flex-row items-center">
                      <GitCommitHorizontal size={18} />
                      <div className="ml-2 text-zinc-950">
                        {generation.sourceTag}
                      </div>
                    </div>
                  </div>
                  <div className="justify-self-end">
                    <div className="flex flex-row items-center">
                      {generation.elapsedTime} by{' '}
                      {generation.getCreatedBy().name.slice(0, MAX_NAME_LENGTH)}
                      <Avatar className="h-6 w-6 ml-2">
                        <AvatarImage
                          src={
                            generation.getCreatedBy().avatar ??
                            'https://www.w3schools.com/howto/img_avatar.png'
                          }
                        />
                      </Avatar>
                    </div>
                  </div>
                </GenerationContainer>
              </a>
            ))}
        </div>

        {process.generationPageStore.getTotalNumberOfItemsInDB() > 0 && (
          <Pagination className="mt-3">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() =>
                    navigate(
                      currentPage <= 1
                        ? `?page=${currentPage}`
                        : `?page=${currentPage - 1}`
                    )
                  }
                />
              </PaginationItem>

              {Array.from(
                { length: numberOfPages },
                (_, index) => index + 1
              ).map((pageNumber) => {
                const shouldShowPage =
                  pageNumber === 1 ||
                  pageNumber === numberOfPages ||
                  Math.abs(pageNumber - currentPage) <= 1;

                if (!shouldShowPage) {
                  if (pageNumber === 2 || pageNumber === numberOfPages - 1) {
                    return (
                      <PaginationItem key={`ellipsis-${pageNumber}`}>
                        <PaginationEllipsis />
                      </PaginationItem>
                    );
                  }
                  return null;
                }

                return (
                  <PaginationItem key={pageNumber}>
                    <PaginationLink
                      onClick={() =>
                        setSearchParams({ page: pageNumber.toString() })
                      }
                      isActive={pageNumber === currentPage}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                );
              })}

              <PaginationItem>
                <PaginationNext
                  onClick={() =>
                    navigate(
                      currentPage >= numberOfPages
                        ? `?page=${currentPage}`
                        : `?page=${currentPage + 1}`
                    )
                  }
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )}
      </SettingBody>
    </SettingsContent>
  );
};

export default observer(Generation);
