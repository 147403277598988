import { useEffect } from 'react';

import { observer } from 'mobx-react';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from '@components/ui/carousel';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';

import useProcess from '@hooks/useProcess';

import { WorkflowModel } from '@models/workflow.model';

import { DefaultPage } from '@atoms/defaultPage/style';

import { CircularProgress } from '@mui/joy';

import LastGenerations from './lastgenerations';
import {
  OverviewContainer,
  OverviewItem,
  OverviewTypeTitle
} from './process.style';
import WorkflowCard from './workflowCard';

const ProcessOverview = () => {
  const process = useProcess();
  const workflows = process && process.workflows;
  useEffect(() => {
    if (!process || process.workflows.length < 1) return;
  }, [process, process?.workflows]);

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }
  return (
    <DefaultPage>
      <OverviewContainer>
        <OverviewItem>
          <OverviewTypeTitle>Workflows</OverviewTypeTitle>
          <Carousel>
            <CarouselContent className="h-[250px]">
              {workflows &&
                workflows.length > 0 &&
                workflows.map((workflow: WorkflowModel) => (
                  <CarouselItem
                    key={workflow.id}
                    className="md:basis-1/2 lg:basis-1/3"
                  >
                    <WorkflowCard
                      key={workflow.id}
                      processId={process.id}
                      workflow={workflow}
                      loading={false}
                    />
                  </CarouselItem>
                ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </OverviewItem>
        <OverviewItem>
          <OverviewTypeTitle>Last generations</OverviewTypeTitle>
          <LastGenerations />
        </OverviewItem>
      </OverviewContainer>
    </DefaultPage>
  );
};

export default observer(ProcessOverview);
