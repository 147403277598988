import { useMemo } from 'react';

import { observer } from 'mobx-react';
import { GenerationStatus } from 'shared';

import { Avatar, AvatarImage } from '@components/ui/avatar';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';
import { getTimeAgo } from '@pages/Home/processCard/processCard.utils';
import {
  MAX_NAME_LENGTH,
  generationStatusToProgress
} from '@pages/Settings/generation';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { GenerationModel } from '@models/generation.model';
import { ProcessModel } from '@models/process.model';
import { WorkflowModel } from '@models/workflow.model';

import { CircularProgress } from '@mui/joy';

import {
  LastEditInfo,
  Side,
  WorkflowCardContainer,
  WorkflowInfos,
  WorkflowName,
  WorkflowPreview,
  WorkflowStatus
} from '../process.style';
import BpmnPreview from './bpmnPreview';

interface WorkflowCardProps {
  workflow: WorkflowModel;
  processId: ProcessModel['id'];
  loading?: boolean;
}

const WorkflowCard = ({
  workflow,
  processId,
  loading = false
}: WorkflowCardProps) => {
  const process = useProcess();
  const { processStore } = useStores();

  let lastGenerations: GenerationModel[] = useMemo(() => {
    return [];
  }, []);

  const editorPath = `/${processId}/${workflow.id}/editor`;
  const avatar =
    lastGenerations &&
    lastGenerations[0] &&
    lastGenerations[0].getCreatedBy().avatar;

  void (process?.generationPageStore.isPageCached(1)
    ? (lastGenerations = process.generationPageStore.getPageItems(1))
    : process && processStore.fetchGenerationsForProcess(process, 1));

  if (!process || !workflow) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  return (
    <WorkflowCardContainer $isLoading={loading} to={loading ? '' : editorPath}>
      <WorkflowPreview>
        <BpmnPreview xml={workflow.workflowXML} />
      </WorkflowPreview>

      {processStore.isGenerationPageFetching(process.id, 1) ? (
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      ) : (
        <WorkflowInfos>
          <Side className="truncate">
            <WorkflowName>{workflow.name}</WorkflowName>

            {lastGenerations.length > 0 && (
              <div className="flex flex-col">
                <LastEditInfo>
                  last updated {getTimeAgo(workflow.updatedAt)}
                </LastEditInfo>{' '}
                <LastEditInfo className="flex">
                  by{' '}
                  {lastGenerations[0]
                    .getCreatedBy()
                    .name.slice(0, MAX_NAME_LENGTH)}{' '}
                  <Avatar className="h-5 w-5 ml-2">
                    <AvatarImage
                      src={
                        avatar ??
                        'https://www.w3schools.com/howto/img_avatar.png'
                      }
                    />
                  </Avatar>
                </LastEditInfo>
              </div>
            )}
          </Side>
          <Side className="items-end text-right">
            <WorkflowStatus>
              {lastGenerations.length > 0
                ? generationStatusToProgress(lastGenerations[0].status, 16)
                : generationStatusToProgress(GenerationStatus.PENDING, 16)}
            </WorkflowStatus>
            {lastGenerations.length > 0 && (
              <LastEditInfo>
                generated {lastGenerations[0].elapsedTime}{' '}
              </LastEditInfo>
            )}
          </Side>
        </WorkflowInfos>
      )}
    </WorkflowCardContainer>
  );
};

export default observer(WorkflowCard);
