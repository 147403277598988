import { envConfig } from '@/env';

export enum EnvironmentName {
  Local = 'local',
  Demo = 'demo',
  Sandbox = 'sandbox',
  Staging = 'staging',
  Preprod = 'preprod',
  Release = 'release'
}

export const ENV_TO_READABLE_NAME = {
  local: 'Local',
  demo: 'Demo',
  sandbox: 'Sandbox',
  staging: 'Staging',
  preprod: 'Pre-Production',
  release: 'Production'
} as const;

export const ANALYTICS_API_URL: string = envConfig.VITE_ANALYTICS_API_URL;
export const ANALYTICS_UI_URL: string = envConfig.VITE_ANALYTICS_UI_URL;
export const ACCOUNT_API_URL: string = envConfig.VITE_ACCOUNT_API_URL;
export const TRACE_UI_URL: string = envConfig.VITE_TRACE_UI_URL;
export const TRACE_API_URL: string = envConfig.VITE_TRACE_API_URL;
export const STUDIO_API_URL: string = envConfig.VITE_STUDIO_API_URL;
export const ACCOUNT_UI_URL: string = envConfig.VITE_ACCOUNT_UI_URL;
export const VITE_ENV: string = envConfig.VITE_ENV;
export const CLIENT_ID = envConfig.VITE_CLIENT_ID.toString();

export const ROUTE_PROCESSES = '/';
export const ENV: EnvironmentName = envConfig.VITE_ENV as EnvironmentName;
