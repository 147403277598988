import { observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';

import { SubheaderItem } from '@components/header/base/subheader/sub-header-item';
import { SubHeader } from '@components/header/base/subheader/sub-header.comp';

import { WORKFLOW_MENU_ITEMS } from '@pages/Settings/menu';

import useProcess from '@hooks/useProcess';

import { ParamsList } from '@/routes/routes.types';

export const WorkflowLayout = () => {
  const process = useProcess();
  const processId = useParams()[ParamsList.ProcessId] as string;
  const currentWorkflowId = useParams()[ParamsList.WorkflowId];

  const navigate = (link: string) =>
    `/${processId}/${currentWorkflowId}/${link}`;

  const canEdit = process?.getPermission().can_edit;

  return (
    <>
      {canEdit && (
        <SubHeader>
          {WORKFLOW_MENU_ITEMS.map(({ name, link }) => (
            <SubheaderItem
              key={link}
              name={name}
              link={link}
              to={navigate(link)}
            />
          ))}
        </SubHeader>
      )}
      <Outlet />
    </>
  );
};

export default observer(WorkflowLayout);
