import { CustomCellRendererProps } from 'ag-grid-react';
import { RowData } from 'shared';
import {
  AtomReference,
  RowReference
} from 'shared/src/atom/atomReference.schema';

import { GroupedBadges } from '@components/database/groupedBadges';

import { useRowReferences } from '@hooks/useRowReferences';

export const BadgeRenderer = (
  params: CustomCellRendererProps<RowData, AtomReference[] | undefined>
) => {
  // TODO: find a working way to simulate focus/edit state to trigger tooltip
  // useEffect(() => {
  //   if (params.node.rowIndex !== null && params.column?.getId() !== undefined) {
  //     params.api.setFocusedCell(params.node.rowIndex, params.column.getId());
  //   }
  // }, []);

  const rowReferences: RowReference[] = useRowReferences(params.value ?? []);

  const selectedOptions = rowReferences.map((rowRef) => {
    return {
      label: rowRef.displayedValue,
      value: rowRef.dataItemId
    };
  });

  return <GroupedBadges selectedOptions={selectedOptions} />;
};
