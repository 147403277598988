import { RefreshCcw } from 'lucide-react';
import { observer } from 'mobx-react';

import useProcess from '@hooks/useProcess';

import { ProcessModel } from '@models/process.model';

import { BasicModal } from '@atoms/modal';

import { Button, DialogActions, Divider } from '@mui/joy';

interface GenerateProps {
  openModalGenerate: boolean;
  setOpenModalGenerate: React.Dispatch<React.SetStateAction<boolean>>;
  onGenerate: () => void;
  workflowList: ProcessModel['workflows'];
}

const GenerateModal = ({
  openModalGenerate,
  setOpenModalGenerate,
  onGenerate,
  workflowList
}: GenerateProps) => {
  const process = useProcess();

  return (
    <BasicModal
      open={openModalGenerate}
      setOpen={setOpenModalGenerate}
      startIcon={<RefreshCcw />}
      title={`Generate the process`}
      width="500px"
    >
      <Divider />
      <h2 className="text-base text-left leading-6">
        Are you sure you want to generate the process ?
      </h2>
      <p className="text-base  leading-6">
        The process <strong>{process?.getName()}</strong> has{' '}
        {workflowList.length}{' '}
        {workflowList.length > 1 ? 'workflows' : 'workflow'} :
      </p>

      <ul>
        {workflowList.map((wf) => (
          <li key={wf.id} className="py-1">
            • {wf.name}
          </li>
        ))}
      </ul>
      <DialogActions>
        <Button
          variant="solid"
          onClick={() => {
            onGenerate();
            setOpenModalGenerate(false);
          }}
        >
          Generate
        </Button>
        <Button
          variant="plain"
          color="neutral"
          onClick={() => setOpenModalGenerate(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </BasicModal>
  );
};

export default observer(GenerateModal);
