import { z } from 'zod';

const configSchema = z.object({
  VITE_ACCOUNT_API_URL: z.string().url(),
  VITE_ANALYTICS_API_URL: z.string().url(),
  VITE_ANALYTICS_UI_URL: z.string().url(),
  VITE_TRACE_UI_URL: z.string().url(),
  VITE_TRACE_API_URL: z.string().url(),
  VITE_ACCOUNT_UI_URL: z.string().url(),
  VITE_STUDIO_API_URL: z.string().url(),
  VITE_CLIENT_ID: z.number(),
  VITE_ENV: z.string()
});

const configs = {
  local: {
    VITE_ACCOUNT_API_URL: 'http://account-api.local.stratumn.com:4200',
    VITE_ANALYTICS_API_URL: 'http://analytics-api.local.stratumn.com:4800',
    VITE_ANALYTICS_UI_URL: 'http://analytics.local.stratumn.com:4802',
    VITE_TRACE_UI_URL: 'http://trace.local.stratumn.com:4101',
    VITE_TRACE_API_URL: 'http://trace-api.local.stratumn.com:4100',
    VITE_STUDIO_API_URL: 'http://studio.local.stratumn.com:5000',
    VITE_ACCOUNT_UI_URL: 'http://account.local.stratumn.com:4201',
    VITE_CLIENT_ID: 1,
    VITE_ENV: 'local'
  },
  staging: {
    VITE_ACCOUNT_API_URL: 'https://account-api.staging.stratumn.com',
    VITE_ANALYTICS_API_URL: 'https://analytics-api.staging.stratumn.com',
    VITE_ANALYTICS_UI_URL: 'https://analytics.staging.stratumn.com',
    VITE_TRACE_UI_URL: 'https://trace.staging.stratumn.com',
    VITE_TRACE_API_URL: 'https://trace-api.staging.stratumn.com',
    VITE_ACCOUNT_UI_URL: 'https://account.staging.stratumn.com',
    VITE_STUDIO_API_URL: 'https://studio-api.staging.stratumn.com',
    VITE_CLIENT_ID: 9,
    VITE_ENV: 'staging'
  },
  preprod: {
    VITE_ACCOUNT_API_URL: 'https://account-api.preprod.stratumn.com',
    VITE_ANALYTICS_API_URL: 'https://analytics-api.preprod.stratumn.com',
    VITE_ANALYTICS_UI_URL: 'https://analytics.preprod.stratumn.com',
    VITE_TRACE_UI_URL: 'https://trace.preprod.stratumn.com',
    VITE_TRACE_API_URL: 'https://trace-api.preprod.stratumn.com',
    VITE_ACCOUNT_UI_URL: 'https://account.preprod.stratumn.com',
    VITE_STUDIO_API_URL: 'https://studio-api.preprod.stratumn.com',
    VITE_CLIENT_ID: 1,
    VITE_ENV: 'preprod'
  },
  preprod_us: {
    VITE_ACCOUNT_API_URL: 'https://account-api.preprod.us.stratumn.com',
    VITE_ANALYTICS_API_URL: 'https://analytics-api.preprod.us.stratumn.com',
    VITE_ANALYTICS_UI_URL: 'https://analytics.preprod.us.stratumn.com',
    VITE_TRACE_UI_URL: 'https://trace.preprod.us.stratumn.com',
    VITE_TRACE_API_URL: 'https://trace-api.preprod.us.stratumn.com',
    VITE_ACCOUNT_UI_URL: 'https://account.preprod.us.stratumn.com',
    VITE_STUDIO_API_URL: 'https://studio-api.preprod.us.stratumn.com',
    VITE_CLIENT_ID: 1,
    VITE_ENV: 'preprod-us'
  },
  release: {
    VITE_ACCOUNT_API_URL: 'https://account-api.stratumn.com',
    VITE_ANALYTICS_API_URL: 'https://analytics-api.stratumn.com',
    VITE_ANALYTICS_UI_URL: 'https://analytics.stratumn.com',
    VITE_TRACE_UI_URL: 'https://trace.stratumn.com',
    VITE_TRACE_API_URL: 'https://trace-api.stratumn.com',
    VITE_ACCOUNT_UI_URL: 'https://account.stratumn.com',
    VITE_STUDIO_API_URL: 'https://studio-api.stratumn.com',
    VITE_CLIENT_ID: 5,
    VITE_ENV: 'release'
  },
  release_us: {
    VITE_ACCOUNT_API_URL: 'https://account-api.us.stratumn.com',
    VITE_ANALYTICS_API_URL: 'https://analytics-api.us.stratumn.com',
    VITE_ANALYTICS_UI_URL: 'https://analytics.us.stratumn.com',
    VITE_TRACE_UI_URL: 'https://trace.us.stratumn.com',
    VITE_TRACE_API_URL: 'https://trace-api.us.stratumn.com',
    VITE_ACCOUNT_UI_URL: 'https://account.us.stratumn.com',
    VITE_STUDIO_API_URL: 'https://studio-api.us.stratumn.com',
    VITE_CLIENT_ID: 5,
    VITE_ENV: 'release'
  },
  demo: {
    VITE_ACCOUNT_API_URL: 'https://account-api.demo.stratumn.com',
    VITE_ANALYTICS_API_URL: 'https://analytics-api.demo.stratumn.com',
    VITE_ANALYTICS_UI_URL: 'https://analytics.demo.stratumn.com',
    VITE_TRACE_UI_URL: 'https://trace.demo.stratumn.com',
    VITE_TRACE_API_URL: 'https://trace-api.demo.stratumn.com',
    VITE_ACCOUNT_UI_URL: 'https://account.demo.stratumn.com',
    VITE_STUDIO_API_URL: 'https://studio-api.demo.stratumn.com',
    VITE_CLIENT_ID: 1,
    VITE_ENV: 'demo'
  }
};

const getConfig = () => {
  const hostname = window.location.hostname;
  let envConfig;

  if (hostname.includes('staging')) {
    envConfig = configs.staging;
  } else if (hostname.includes('preprod.us')) {
    envConfig = configs.preprod_us;
  } else if (hostname.includes('preprod')) {
    envConfig = configs.preprod;
  } else if (hostname.includes('.us.')) {
    envConfig = configs.release_us;
  } else if (hostname.includes('local')) {
    envConfig = configs.local;
  } else if (hostname.includes('demo')) {
    envConfig = configs.demo;
  } else {
    envConfig = configs.release;
  }

  const validatedConfig = configSchema.safeParse(envConfig);

  if (!validatedConfig.success) {
    new Error('Configuration validation failed');
    throw new Error('Configuration validation failed');
  }

  return validatedConfig.data;
};

export const envConfig = getConfig();
