import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

const PADDING = 20; // px
export const SettingsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  height: calc(100% + ${PADDING}px);
  padding: ${PADDING}px;
  padding-bottom: 5px;
  padding-right: 0;
  background: #fafafa;
`;

export const SettingsMenu = styled.div`
  height: calc(100% - 90px);
  width: 300px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px -1px 15px 0px #0000000d;
`;

export const SettingsHeaderContainer = styled.div`
  margin-bottom: 40px;
`;

export const SettingsTitle = styled(Link)`
  padding: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 30px auto;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey[6]};

  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  svg {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    stroke: ${({ theme }) => theme.color.grey[4]};
  }
`;

export const MenuItems = styled.div`
  padding: 10px 10px;
`;

export const MenuItem = styled(NavLink)`
  display: grid;
  align-items: center;
  grid-template-columns: 30px auto;
  padding: 5px 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.color.grey[3]};
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;

  svg {
    stroke: ${({ theme }) => theme.color.grey[3]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.verRail.background.contentHover};
    color: #000;
  }

  &.active {
    color: ${({ theme }) => theme.color.grey.dark};
    border: none;
    font-weight: 500;

    svg {
      stroke: ${({ theme }) => theme.color.grey[1]};
    }
  }
`;

export const SettingsContentContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: auto;
  flex-grow: 1;
  scrollbar-gutter: stable both-edges;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.grey[6]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.grey[4]};
  }
`;

export const SettingsContent = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 850px;
`;

export const GeneralSettingsInput = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
`;

export const ButtonContainer = styled.footer`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: end;
  box-sizing: border-box;
  margin-top: 20px;
`;

export const GeneralSettingsTitle = styled.h3`
  margin-bottom: 20px;
  padding-bottom: 0px;
`;

export const RowDiv = styled.div`
  display: flex;
  flexdirection: row;
  gap: 1rem;
  align-items: end;
  padding-top: 1rem;
`;
