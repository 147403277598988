import { Action, BaseWorkflow, Workflow } from 'shared';

import { ActionModel } from '@models/action/action.model';
import { WorkflowModel } from '@models/workflow.model';

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { DndLibrary } from '@library';

export class WorkflowStore extends BaseStore<WorkflowModel> {
  currentWorkflow: Maybe<WorkflowModel>;
  constructor(rootStore: RootStore) {
    super(rootStore, WorkflowModel, 'workflow');
  }

  public setCurrentWorkflow(workflowModel: WorkflowModel) {
    this.currentWorkflow = workflowModel;
  }

  public loadWorkflows = (loadedWorkflows: Workflow[]): string[] => {
    return loadedWorkflows.map((loadedWorkflow) => {
      return this.loadWorkflow(loadedWorkflow);
    });
  };

  public loadBaseWorkflow = (
    workflowLoaded: BaseWorkflow
  ): Pick<BaseWorkflow, 'id' | 'published_id'> => {
    const workflow = new WorkflowModel(this, {
      id: workflowLoaded.id,
      workflowXML: workflowLoaded.workflow_xml,
      name: workflowLoaded.metadata.name ?? 'undefined',
      published_id: workflowLoaded.published_id,
      id_environment_variable_name:
        workflowLoaded.metadata.id_environment_variable_name,
      updatedAt: workflowLoaded.updatedAt
    });

    this.set(workflow.id, workflow);

    return {
      id: workflow.id,
      published_id: workflow.published_id
    };
  };

  private loadWorkflow = (workflowLoaded: Workflow): string => {
    const dndStore = this.rootStore.dndStore;
    const actionStore = this.rootStore.actionStore;

    const traceInfoDND = dndStore.createDndFromLoaded(
      DndLibrary.TraceInfo,
      workflowLoaded.traceInfo
    );

    const traceOverviewDND = dndStore.createDndFromLoaded(
      DndLibrary.TraceOverview,
      workflowLoaded.traceOverview
    );

    const globalVariablesDND = dndStore.createDndFromLoaded(
      DndLibrary.GlobalVariables,
      workflowLoaded.globalVariables
    );

    /* ---------------------- Handle actions and their DNDs --------------------- */

    const actionIds: ActionModel['id'][] = [];
    workflowLoaded.Action.forEach((action: Action) => {
      const newAction = actionStore.addLoadedActionToStore(action);

      actionIds.push(newAction.id);
    });

    /* ------------------------ Instanciate WorkflowModel ----------------------- */
    const workflow = new WorkflowModel(this, {
      id: workflowLoaded.id,
      workflowXML: workflowLoaded.workflow_xml,
      published_id: workflowLoaded.published_id,
      traceInfoDNDId: traceInfoDND.id,
      traceOverviewDndId: traceOverviewDND.id,
      globalVariablesDndId: globalVariablesDND.id,
      actionIds,
      name: workflowLoaded.metadata.name ?? 'No name',
      updatedAt: workflowLoaded.updatedAt
    });

    this.rootStore.workflowStore.set(workflowLoaded.id, workflow);

    return workflow.id;
  };
}
