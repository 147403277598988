import { createZodDto } from 'nestjs-zod';
import { z } from 'zod';

import { AtomReferenceSchema } from '../atom/atomReference.schema';
import { TraceKeySchema } from '../other/traceKey.schema';

export enum CellDataType {
  Text = 'text',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  DateString = 'dateString',
  Object = 'object'
}

export const ColumnContextSchema = z
  .object({
    reference: z
      .object({
        databaseId: z.string()
      })
      .optional(),
    isPrimaryKey: z.boolean().optional(),
    editableAfterGeneration: z.boolean().default(true).optional(),
    regex: z.string().optional()
  })
  .nullish();
export type ColumnContext = z.infer<typeof ColumnContextSchema>;

export const DatabaseColumnSchema = z
  .object({
    field: z.string(),
    headerName: z.string().optional(),
    cellDataType: z.nativeEnum(CellDataType),
    context: ColumnContextSchema
  })
  .passthrough();
export type Column = z.infer<typeof DatabaseColumnSchema>;

export const ColumnsSchema = z.array(DatabaseColumnSchema);
export type Columns = z.infer<typeof ColumnsSchema>;

export const DatabaseSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    traceKey: TraceKeySchema,
    columnDefinitions: ColumnsSchema,
    rowReferences: z.array(AtomReferenceSchema),
    processId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    deletedAt: z.string().datetime().nullable()
  })
  .strict();
export type Database = z.infer<typeof DatabaseSchema>;

export class DatabaseDto extends createZodDto(DatabaseSchema) {}

export const UpdateDatabaseSchema = DatabaseSchema.omit({
  id: true
});
export type UpdateDatabase = z.infer<typeof UpdateDatabaseSchema>;

export class UpdateDatabaseDto extends createZodDto(UpdateDatabaseSchema) {}
