const programsRowData = [
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'ID/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'CH/CREDIT ANVIL/QS',
    proportional: true
  },
  {
    name: 'CH/ENGINEERING/SP',
    proportional: true
  },
  {
    name: 'CH/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'CH/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'CH/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'SGR CH/RCA RCG/XL',
    proportional: false
  },
  {
    name: 'SGR CH/ACCIDENTS /XL',
    proportional: false
  },
  {
    name: 'CH/CYBER/QS',
    proportional: true
  },
  {
    name: 'CH/PTY AND MOTOR HULL CAT/XL',
    proportional: false
  },
  {
    name: 'CH/MTPL MOTOR PA/XL',
    proportional: false
  },
  {
    name: 'CH/CREDIT BOND/ XL',
    proportional: false
  },
  {
    name: 'AXL/BOND/QS',
    proportional: true
  },
  {
    name: 'AXL/GENERAL AVIATION /QS',
    proportional: true
  },
  {
    name: 'AXL/EDP MONDE ENTIER/SP',
    proportional: true
  },
  {
    name: 'AXL/MARINE YACHT/QS',
    proportional: true
  },
  {
    name: 'AXL/AVIATION HULL WAR/QS',
    proportional: true
  },
  {
    name: 'AXL/AVIATION/QS',
    proportional: true
  },
  {
    name: 'AXL/GENERAL AVIATION WHOLE ACCOUNT/XL',
    proportional: false
  },
  {
    name: 'AXL/GENERAL AVIATION HULL ACCOUNT/XL',
    proportional: false
  },
  {
    name: 'AXL/COMBINE MARINE AVIATION/XL',
    proportional: false
  },
  {
    name: 'AXL/SMALL AVIATION SUPPLIERS/QS',
    proportional: true
  },
  {
    name: 'AXL/MARINE WHOLE ACCOUNT/XL',
    proportional: false
  },
  {
    name: 'AXL/CARGO/ XL',
    proportional: false
  },
  {
    name: 'AXL/ MARINE WAR CCR / QS',
    proportional: true
  },
  {
    name: 'AXL/MARINE WAR/SL',
    proportional: false
  },
  {
    name: 'AXL/MARINE YACHT/SP',
    proportional: true
  },
  {
    name: 'AXL/EMA EMMLA/ XL',
    proportional: false
  },
  {
    name: 'AXL/SPACE/QS',
    proportional: true
  },
  {
    name: 'AXL/CAR EAR/XL',
    proportional: false
  },
  {
    name: 'AXL/CAR EAR /SP',
    proportional: true
  },
  {
    name: 'AXL/CYBER/QS',
    proportional: true
  },
  {
    name: 'AXL/D&O/QS',
    proportional: true
  },
  {
    name: 'AXL/DECENNIAL RISK/XL',
    proportional: false
  },
  {
    name: 'AXL/ENERGY & CHEMICALS/QS',
    proportional: true
  },
  {
    name: 'AXL/ENERGY & CHEMICALS/XL',
    proportional: false
  },
  {
    name: 'AXL/CASUALTY RISK AND EVENT/XL',
    proportional: false
  },
  {
    name: 'AXL/MOTOR & NON MOTOR NAT CAT CCR/QS',
    proportional: true
  },
  {
    name: 'AXL/MOTOR & NON MOTOR NAT CAT CCR/SL',
    proportional: false
  },
  {
    name: 'AXL/MOTOR THIRD PARTY LIABILITY/XL',
    proportional: false
  },
  {
    name: 'AXL / PARAMETRIC/SL',
    proportional: false
  },
  {
    name: 'AXL/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AXL/PROPERTY NET FREQUENCY PROTECTION/XL',
    proportional: false
  },
  {
    name: 'AXL/FINANCIAL LINES/QS',
    proportional: true
  },
  {
    name: 'AXL/AXA GI CANADIAN FRONTING/QS',
    proportional: true
  },
  {
    name: 'AXL/CAT AGGREGATE/XL',
    proportional: false
  },
  {
    name: 'AXL/CAT INSURANCE TOWER/XL',
    proportional: false
  },
  {
    name: 'AXL/GROUP PA/XL',
    proportional: false
  },
  {
    name: 'AXL/MOTOR DAMAGE/XL',
    proportional: false
  },
  {
    name: 'AXL/PARAMETRIC/QS SP',
    proportional: true
  },
  {
    name: 'AXL/PROPERTY CAT TOP AND DROP/XL',
    proportional: false
  },
  {
    name: 'AXL/CEDED RE PROPERTY/QS',
    proportional: true
  },
  {
    name: 'AXL/TERRORISME CCR/SL',
    proportional: false
  },
  {
    name: 'CO/BANCOLOMBIA PROPERTY CAT/QS',
    proportional: true
  },
  {
    name: 'CO/BONDS/QS',
    proportional: true
  },
  {
    name: 'CO/ENGINEERING/QS',
    proportional: true
  },
  {
    name: 'CO/SPECIALTY LINES TPL/QS',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'CO/BONDS/XL',
    proportional: false
  },
  {
    name: 'CO/BANCO COLPATRIA CAT/QS',
    proportional: true
  },
  {
    name: 'CO/CAT FONDO NACIONAL DE AHORRO/QS',
    proportional: true
  },
  {
    name: 'CO/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'CO/TPL/XL',
    proportional: false
  },
  {
    name: 'CO/WORKERS COMPENSATION/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM CO/MARINE/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM CO/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'CO/MARINE/QS',
    proportional: true
  },
  {
    name: 'CO/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'CO/LIFE/SP',
    proportional: true
  },
  {
    name: 'CO/LIFE GROUP/XL',
    proportional: false
  },
  {
    name: 'CO/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'TR/ENGINEERING FIRE/QS',
    proportional: true
  },
  {
    name: 'TR/CAR EAR FIRE/SP',
    proportional: true
  },
  {
    name: 'TR/GENERAL ACCIDENT/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'TR/HOUSEHOLDERS/QS',
    proportional: true
  },
  {
    name: 'TR/ENGINEERING NON MOTOR ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'TR/HAZARDOUS MATERIAL/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM TR/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM OR/MARINE/XL',
    proportional: false
  },
  {
    name: 'TR/MOTOR EXTENDED WARRANTY/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM SE TR/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'TR/PA/SP',
    proportional: true
  },
  {
    name: 'TR/LIFE/SP',
    proportional: true
  },
  {
    name: 'ES/BOND/XL',
    proportional: false
  },
  {
    name: 'ES/BOND/QS',
    proportional: true
  },
  {
    name: 'ES/CYBER RISKS/QS',
    proportional: true
  },
  {
    name: 'ES SE/MARINE/XL',
    proportional: false
  },
  {
    name: 'ES/DECENNIAL/XL',
    proportional: false
  },
  {
    name: 'SE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'ES/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ES/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ES SE/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ES/MTPL/XL',
    proportional: false
  },
  {
    name: 'ES/GTPL/XL',
    proportional: false
  },
  {
    name: 'ES/GTPL PUBLIC ADMINISTRATION/XL',
    proportional: false
  },
  {
    name: 'ES/LIFE PA PROFESSIONAL SPORT PLAYERS/XL',
    proportional: false
  },
  {
    name: 'ES/CAT CLIMATE EVENTS/XL',
    proportional: false
  },
  {
    name: 'SE/LIFE REGIONAL/XL',
    proportional: false
  },
  {
    name: 'ES/LIFE INDIVIDUAL/SP',
    proportional: true
  },
  {
    name: 'ES/LIFE GROUP/SP',
    proportional: true
  },
  {
    name: 'ES/LIFE LTC/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM MX/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM MX/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM MX/MARINE/XL',
    proportional: false
  },
  {
    name: 'MX/MISCELLANEOUS/XL',
    proportional: false
  },
  {
    name: 'MX/MOTOR/XL',
    proportional: false
  },
  {
    name: 'MX/GTPL/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MX/CARGO/SP',
    proportional: true
  },
  {
    name: 'MX/ENGINEERING/SP',
    proportional: true
  },
  {
    name: 'MX/HULL/QS',
    proportional: true
  },
  {
    name: 'MX/AVIATION/XL',
    proportional: false
  },
  {
    name: 'MX/FINANCIAL LINES/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MX/CAT OVERSEAS/XL',
    proportional: false
  },
  {
    name: 'MX/CYBER/QS',
    proportional: true
  },
  {
    name: 'MX/RESIDENTIAL/QS',
    proportional: true
  },
  {
    name: 'MX/TENT PLAN/XL',
    proportional: false
  },
  {
    name: 'MX/LIFE GROUP/XL',
    proportional: false
  },
  {
    name: 'MX/LIFE MEDEX/XL',
    proportional: false
  },
  {
    name: 'MX/LIFE INDIVIDUAL/SP',
    proportional: true
  },
  {
    name: 'MX/FRONTING AMEXCO/QS',
    proportional: true
  },
  {
    name: 'MX/LIFE GROUP/SP',
    proportional: true
  },
  {
    name: 'MX/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/QS',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY/SP',
    proportional: true
  },
  {
    name: 'MY/BOND/QS',
    proportional: true
  },
  {
    name: 'MY/LIABILITY/XL',
    proportional: false
  },
  {
    name: 'MY/MARINE/XL',
    proportional: false
  },
  {
    name: 'MY/PA/XL',
    proportional: false
  },
  {
    name: 'MY/PROPERTY RISK CAT/XL',
    proportional: false
  },
  {
    name: 'MY/PROPERTY/SP',
    proportional: true
  },
  {
    name: 'MY/UMBRELLA/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/QS',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'HK/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/QS SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ASIA PH/BOND/QS',
    proportional: true
  },
  {
    name: 'ASIA PH/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'PH/LIFE INDIVIDUAL SP',
    proportional: true
  },
  {
    name: 'PH/LIFE GROUP SP',
    proportional: true
  },
  {
    name: 'PH/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'TH/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'JP/MOTOR/QS',
    proportional: true
  },
  {
    name: 'JP/MTPL incl PA/XL',
    proportional: false
  },
  {
    name: 'JP/MOD CAT/XL',
    proportional: false
  },
  {
    name: 'JP/LIFE INDIVIDUAL SUW/QS',
    proportional: true
  },
  {
    name: 'JP/LIFE EPIDEMIC AND PANDEMIC/XL',
    proportional: false
  },
  {
    name: 'KR/MOTOR/QS',
    proportional: true
  },
  {
    name: 'KR/MOTOR/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'DZ/MARINE CARGO/QS',
    proportional: true
  },
  {
    name: 'DZ/MARINE CARGO/XL',
    proportional: false
  },
  {
    name: 'DZ/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'DZ/PROPERTY RISK/ QS SP',
    proportional: true
  },
  {
    name: 'DZ/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'BE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'BE/ENGINEERING/SP',
    proportional: true
  },
  {
    name: 'BE/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'BE/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'BE/CASUALTY/XL',
    proportional: false
  },
  {
    name: 'BE/CASUALTY/QS',
    proportional: true
  },
  {
    name: 'SGR BE/CONSTRUCTION/SP',
    proportional: true
  },
  {
    name: 'SGR BE/RCA RCG/XL',
    proportional: false
  },
  {
    name: 'SGR BE/ACCIDENTS/XL',
    proportional: false
  },
  {
    name: 'BE/CYBER/QS',
    proportional: true
  },
  {
    name: 'BE/LIFE/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'LB/CASUALTY/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM OR/MARINE/XL',
    proportional: false
  },
  {
    name: 'LB/NON MARINE/QS SP',
    proportional: true
  },
  {
    name: 'REGIONAL INM LB/PROPERTY RISK CAT/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'LB/GROUP INDIVIDUAL LIFE / SP',
    proportional: true
  },
  {
    name: 'TIP/XL',
    proportional: false
  },
  {
    name: 'DE/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'DE/MOTOR/QS',
    proportional: true
  },
  {
    name: 'DE/PROPERTY RISK/QS',
    proportional: true
  },
  {
    name: 'DE/ENGINEERING/QS',
    proportional: true
  },
  {
    name: 'DE/MARINE/QS',
    proportional: true
  },
  {
    name: 'DE/CYBER RISK/QS',
    proportional: true
  },
  {
    name: 'DE/SURETY/XL',
    proportional: false
  },
  {
    name: 'DE/GTPL/XL',
    proportional: false
  },
  {
    name: 'DE/MARINE AVIATION/XL',
    proportional: false
  },
  {
    name: 'DE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'DE/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'DE/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'DE/SICHER MOTOR/XL',
    proportional: false
  },
  {
    name: 'DE/MTPL MOTOR PAPA/XL',
    proportional: false
  },
  {
    name: 'DE/SEMI AUTO FAC/XL',
    proportional: false
  },
  {
    name: 'BE/MOD CAT/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MA/CREDIT/QS',
    proportional: true
  },
  {
    name: 'MA/CAR EAR DECENNIAL/QS SP',
    proportional: true
  },
  {
    name: 'MA/MTPL GTPL PA WC/XL',
    proportional: false
  },
  {
    name: 'MA/ PROPERTY CAT /XL',
    proportional: false
  },
  {
    name: 'MA/PROPERTY ENGINEERING MARINE/ XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM MA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MA/MARINE/XL',
    proportional: false
  },
  {
    name: 'MA/MARINE/QS',
    proportional: true
  },
  {
    name: 'MA/LIFE GROUP INDIVIDUAL/XL',
    proportional: false
  },
  {
    name: 'MA/LIFE GROUP INDIVIDUAL/QS',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'EG/NON MARINE MARINE PROPERTY RISK-CAT/XL',
    proportional: false
  },
  {
    name: 'EG/NON MARINE MARINE PROPERTY/QS SP',
    proportional: true
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'EG/INDIVIDUAL AND GROUP LIFE/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM GF SA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM GF SA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'GF SA/PROPERTY RISK-CAT SUBLAYER/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM OR/MARINE/XL',
    proportional: false
  },
  {
    name: 'GF SA/CASUALTY/XL',
    proportional: false
  },
  {
    name: 'DE/LIFE ALV/SP',
    proportional: true
  },
  {
    name: 'DE/LIFE DBV/SP',
    proportional: true
  },
  {
    name: 'DE/LIFE DAV/SP',
    proportional: true
  },
  {
    name: 'DE/LIFE CAT ALV/XL',
    proportional: false
  },
  {
    name: 'DE/UNIT LINKED CONTRACT ALV/QS',
    proportional: true
  },
  {
    name: 'DE/UNIT LINKED CONTRACT DAV/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM GR/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM GR/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM GR/GTPL MTPL PA AND CASUALTY/XL',
    proportional: false
  },
  {
    name: 'GR SE/MARINE/XL',
    proportional: false
  },
  {
    name: 'SE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'SE/MARINE/QS',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'GR/CYBER RISKS/QS ',
    proportional: true
  },
  {
    name: 'GR/ALP LIFE/XL',
    proportional: false
  },
  {
    name: 'SE/LIFE/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'LU/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'LU/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM LU/MTPL GTPL CASUALTY/XL',
    proportional: false
  },
  {
    name: 'LU/LIFE/SP',
    proportional: true
  },
  {
    name: 'LU/LIFE/XL SL',
    proportional: false
  },
  {
    name: 'IT SE/MTPL/XL',
    proportional: false
  },
  {
    name: 'IT/GTPL/XL',
    proportional: false
  },
  {
    name: 'IT/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'IT/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'IT SE/ENGINEERING DECENNIAL/XL',
    proportional: false
  },
  {
    name: 'IT/HAIL/SL',
    proportional: false
  },
  {
    name: 'IT/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'IT/PROFESSIONAL LIABILITY/QS',
    proportional: true
  },
  {
    name: 'IT/BONDS/QS',
    proportional: true
  },
  {
    name: 'IT/BONDS/XL',
    proportional: false
  },
  {
    name: 'IT/MARINE/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'SGR IT/ RCA RCG/XL',
    proportional: false
  },
  {
    name: 'IT/ATMOSPHERIC EVENTS AGG CAT/XL',
    proportional: false
  },
  {
    name: 'IT/CYBER/QS',
    proportional: true
  },
  {
    name: 'SE/LIFE/XL',
    proportional: false
  },
  {
    name: 'IT/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'IT/LIFE/XL',
    proportional: false
  },
  {
    name: 'MPS SE/MTPL/XL',
    proportional: false
  },
  {
    name: 'MPS/GTPL/XL',
    proportional: false
  },
  {
    name: 'MPS/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'MPS/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'MPS/ENGINEERING DECENNIAL/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MPS/ATMOSPHERIC EVENTS AGG CAT/XL',
    proportional: false
  },
  {
    name: 'MPS/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'SE/LIFE/XL',
    proportional: false
  },
  {
    name: 'MPS/LIFE/XL',
    proportional: false
  },
  {
    name: 'QUIXA SE/MTPL/XL',
    proportional: false
  },
  {
    name: 'QUIXA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'QUIXA/ATMOSPHERIC EVENTS AGG CAT/XL',
    proportional: false
  },
  {
    name: 'QUIXA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'QUIXA/GTPL/XL',
    proportional: false
  },
  {
    name: 'AF/BOND/QS',
    proportional: true
  },
  {
    name: 'AF/CAUTION CREDIT ACQUEREURS/XL',
    proportional: false
  },
  {
    name: 'AF/CYBER/QS',
    proportional: true
  },
  {
    name: 'AF/CAT NAT AUTO/SL',
    proportional: false
  },
  {
    name: 'AF/CAT NAT AUTO NON AUTO/QS',
    proportional: true
  },
  {
    name: 'AF/CAT NAT NON AUTO/SL',
    proportional: false
  },
  {
    name: 'AF/TERROR WAR/SL',
    proportional: false
  },
  {
    name: 'AF/TERROR WAR/QS',
    proportional: true
  },
  {
    name: 'AF/CONSTRUCTION/QS',
    proportional: true
  },
  {
    name: 'AF/CONSTRUCTION/XL',
    proportional: false
  },
  {
    name: 'AF/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AF/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'AF/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'AF/CAR EAR/XL',
    proportional: false
  },
  {
    name: 'AF/GTPL/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL/QS',
    proportional: true
  },
  {
    name: 'AF/INLAND TRANSPORT/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL<1998/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL 1998 to 2004/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL>2004/XL',
    proportional: false
  },
  {
    name: 'SGR/ CONSTRUCTION/XL',
    proportional: false
  },
  {
    name: 'SGR/ PROPERTY/XL',
    proportional: false
  },
  {
    name: 'SGR/ RCA RCG/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS XL',
    proportional: true
  },
  {
    name: 'AF/CREDITOR MOTOR GAP NAT CAT/XL',
    proportional: false
  },
  {
    name: 'AF/GROUP PA PER EVENT/XL',
    proportional: false
  },
  {
    name: 'FR/LIFE/XL SL',
    proportional: false
  },
  {
    name: 'FR/LIFE/XP',
    proportional: true
  },
  {
    name: 'MSC/CAT NAT AUTO NON AUTO/QS',
    proportional: true
  },
  {
    name: 'MSC/CAT NAT AUTO NON AUTO/SL',
    proportional: false
  },
  {
    name: 'MSC/TERRORISM/SL',
    proportional: false
  },
  {
    name: 'MSC/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'MSC/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'MSC/PROPERTY RISK XL',
    proportional: false
  },
  {
    name: 'MSC/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'MSC/CYBER RISKS/QS',
    proportional: true
  },
  {
    name: 'MSC/LIFE/XL',
    proportional: false
  },
  {
    name: 'FR DA/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'FR DA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'FR DA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'FR DA/CAT NAT (CCR)/QS',
    proportional: true
  },
  {
    name: 'FR DA/CAT NAT (CCR)/SL',
    proportional: false
  },
  {
    name: 'FR DA/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'AA IPA/TRAVEL AND LEGAL PROTECTION/XL',
    proportional: false
  },
  {
    name: 'AA IPA/EXPATRIATES COVER/QS',
    proportional: true
  },
  {
    name: 'AA IPA/CEP COVER/QS',
    proportional: true
  },
  {
    name: 'BR/MARINE/XL',
    proportional: false
  },
  {
    name: 'BR/SME/QS',
    proportional: true
  },
  {
    name: 'BR/SURETY BONDS/QS',
    proportional: true
  },
  {
    name: 'BR/SURETY BONDS/XL',
    proportional: false
  },
  {
    name: 'BR/TPL/XL',
    proportional: false
  },
  {
    name: 'BR/PROPERTY ENGINEERING FACILITY/QS',
    proportional: true
  },
  {
    name: 'BR/MULTI PROPERTY/XL',
    proportional: false
  },
  {
    name: 'BR/CYBER/QS',
    proportional: true
  },
  {
    name: 'BR/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'BR/LIFE TRAVEL/XL',
    proportional: false
  },
  {
    name: 'DE/LEGAL INDUSTRIAL XL',
    proportional: false
  },
  {
    name: 'PT/GTPL/XL',
    proportional: false
  },
  {
    name: 'PT/MTPL/XL',
    proportional: false
  },
  {
    name: 'PT/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'PT/WORKERS COMPENSATION/XL',
    proportional: false
  },
  {
    name: 'PT/BONDS/QS',
    proportional: true
  },
  {
    name: 'PT/ENGINEERING/XP QS',
    proportional: true
  },
  {
    name: 'PT/MARINE/QS',
    proportional: true
  },
  {
    name: 'PT/MARINE/XL',
    proportional: false
  },
  {
    name: 'PT/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'PT/PROPERTY CAT/XL',
    proportional: false
  }
];

export const uniqueProgramsRowData = programsRowData.filter(
  (program, index, self) =>
    index === self.findIndex((p) => p.name === program.name)
);

const buidsRowData = [
  {
    BUID: '2789'
  },
  {
    BUID: '2810'
  },
  {
    BUID: '2815'
  },
  {
    BUID: '2839'
  },
  {
    BUID: '2994'
  },
  {
    BUID: '3078'
  },
  {
    BUID: '3080'
  },
  {
    BUID: '3139'
  },
  {
    BUID: '3351'
  },
  {
    BUID: '3352'
  },
  {
    BUID: '3353'
  },
  {
    BUID: '3436'
  },
  {
    BUID: '3667'
  },
  {
    BUID: '3668'
  },
  {
    BUID: '3804'
  },
  {
    BUID: '3805'
  },
  {
    BUID: '3820'
  },
  {
    BUID: '3825'
  },
  {
    BUID: '3834'
  },
  {
    BUID: '3835'
  },
  {
    BUID: '3842'
  },
  {
    BUID: '3861'
  },
  {
    BUID: '3863'
  },
  {
    BUID: '3873'
  },
  {
    BUID: '3882'
  },
  {
    BUID: '3886'
  },
  {
    BUID: '3921'
  },
  {
    BUID: '3934'
  },
  {
    BUID: '3942'
  },
  {
    BUID: '3966'
  },
  {
    BUID: '3982'
  },
  {
    BUID: '4039'
  },
  {
    BUID: '4103'
  },
  {
    BUID: '4113'
  },
  {
    BUID: '4224'
  },
  {
    BUID: '4231'
  },
  {
    BUID: '4232'
  },
  {
    BUID: '4234'
  },
  {
    BUID: '4244'
  },
  {
    BUID: '4257'
  },
  {
    BUID: '4303'
  },
  {
    BUID: '4313'
  },
  {
    BUID: '4325'
  },
  {
    BUID: '4326'
  },
  {
    BUID: '4327'
  },
  {
    BUID: '4328'
  },
  {
    BUID: '4329'
  },
  {
    BUID: '4333'
  },
  {
    BUID: '4334'
  },
  {
    BUID: '4336'
  },
  {
    BUID: '4337'
  },
  {
    BUID: '4339'
  },
  {
    BUID: '4827'
  },
  {
    BUID: '4926'
  },
  {
    BUID: '4930'
  },
  {
    BUID: '5120'
  },
  {
    BUID: '5327'
  },
  {
    BUID: '5341'
  },
  {
    BUID: '5342'
  },
  {
    BUID: '5354'
  },
  {
    BUID: '5360'
  },
  {
    BUID: '5438'
  },
  {
    BUID: '5443'
  },
  {
    BUID: '5474'
  },
  {
    BUID: '5538'
  },
  {
    BUID: '5556'
  },
  {
    BUID: '5560'
  },
  {
    BUID: '5562'
  },
  {
    BUID: '5563'
  },
  {
    BUID: '5567'
  },
  {
    BUID: '5571'
  },
  {
    BUID: '5572'
  },
  {
    BUID: '5582'
  },
  {
    BUID: '5584'
  },
  {
    BUID: '5773'
  },
  {
    BUID: '5817'
  },
  {
    BUID: '5824'
  },
  {
    BUID: '5831'
  },
  {
    BUID: '5927'
  },
  {
    BUID: '6059'
  },
  {
    BUID: '6061'
  },
  {
    BUID: '6109'
  },
  {
    BUID: '6110'
  },
  {
    BUID: '6111'
  },
  {
    BUID: '6407'
  },
  {
    BUID: '6546'
  },
  {
    BUID: '7294'
  },
  {
    BUID: '7598'
  },
  {
    BUID: '8031'
  },
  {
    BUID: '8413'
  },
  {
    BUID: '8479'
  },
  {
    BUID: '8499'
  },
  {
    BUID: '8567'
  },
  {
    BUID: '8855'
  },
  {
    BUID: '9751'
  },
  {
    BUID: '9771'
  },
  {
    BUID: '10001'
  },
  {
    BUID: '10101'
  },
  {
    BUID: '10411'
  },
  {
    BUID: '10439'
  },
  {
    BUID: '10524'
  },
  {
    BUID: '10823'
  },
  {
    BUID: '10878'
  },
  {
    BUID: '10884'
  },
  {
    BUID: '10911'
  },
  {
    BUID: '10913'
  },
  {
    BUID: '10914'
  },
  {
    BUID: '10935'
  },
  {
    BUID: '10942'
  },
  {
    BUID: '11000'
  },
  {
    BUID: '11004'
  },
  {
    BUID: '11071'
  },
  {
    BUID: '11080'
  },
  {
    BUID: '11101'
  },
  {
    BUID: '11128'
  },
  {
    BUID: '11134'
  },
  {
    BUID: '11158'
  },
  {
    BUID: '11252'
  },
  {
    BUID: '11865'
  },
  {
    BUID: '20002'
  },
  {
    BUID: '30003'
  }
];

export const uniqueBuidRowData = buidsRowData.filter(
  (buid, index, self) => index === self.findIndex((b) => b.BUID === buid.BUID)
);

export const entitiesRowData = [
  {
    name: 'AXA MANDIRI /ID',
    label: 'mandiri'
  },
  {
    name: 'AXA LIFE MANDIRI /ID',
    label: 'mandiriLife'
  },
  {
    name: 'AXA SWITZERLAND /CH',
    label: 'winterthur'
  },
  {
    name: 'AXA XL/AXL',
    label: 'xl'
  },
  {
    name: 'AXA COLOMBIA /CO',
    label: 'colombia'
  },
  {
    name: 'AXA LIFE COLOMBIA /CO',
    label: 'colombiaLife'
  },
  {
    name: 'AXA TURKEY /TR',
    label: 'turkey'
  },
  {
    name: 'AXA LIFE TURKEY /TR',
    label: 'turkeyLife'
  },
  {
    name: 'AXA SPAIN /ES',
    label: 'seguros'
  },
  {
    name: 'AXA LIFE SPAIN',
    label: 'spainLife'
  },
  {
    name: 'AXA MEXICO /MX',
    label: 'mexico'
  },
  {
    name: 'AXA LIFE MEXICO /MX',
    label: 'mexicoLife'
  },
  {
    name: 'AXA MALAYSIA /MY',
    label: 'malaysia'
  },
  {
    name: 'AXA HONG-KONG /HK',
    label: 'hongkong'
  },
  {
    name: 'AXA LIFE HONG-KONG /HK',
    label: 'hongkongLife'
  },
  {
    name: 'AXA SINGAPORE /SG',
    label: 'singapore'
  },
  {
    name: 'AXA PHILIPPINES /PH',
    label: 'philippines'
  },
  {
    name: 'AXA LIFE PHILIPPINES /PH',
    label: 'philippinesLife'
  },
  {
    name: 'AXA THAILAND /TH',
    label: 'thailand'
  },
  {
    name: 'AXA LIFE THAILAND /TH',
    label: 'thailandLife'
  },
  {
    name: 'AXA TIANPING /TN',
    label: 'tianping'
  },
  {
    name: 'AGD JAPAN',
    label: 'japan'
  },
  {
    name: 'AXA LIFE JAPAN /JP',
    label: 'japanLife'
  },
  {
    name: 'AGD KOREA',
    label: 'korea'
  },
  {
    name: 'AXA ALGERIE /DZ',
    label: 'algeria'
  },
  {
    name: 'AXA BELGIQUE /BE',
    label: 'belgium'
  },
  {
    name: 'AXA LIFE BELGIQUE /BE',
    label: 'lifeBelgium'
  },
  {
    name: 'AXA MIDDLE EAST /LB',
    label: 'middleEast'
  },
  {
    name: 'AXA LIFE MIDDLE EAST /LB',
    label: 'middleEastLife'
  },
  {
    name: 'AXA GERMANY /DE',
    label: 'versicherung'
  },
  {
    name: 'YUZZU /BE',
    label: 'yuzzu'
  },
  {
    name: 'AXA MAROC /MA',
    label: 'maroc'
  },
  {
    name: 'AXA LIFE MAROC /MA',
    label: 'marocLife'
  },
  {
    name: 'AXA EGYPT /EG',
    label: 'egypt'
  },
  {
    name: 'AXA LIFE EGYPT /EG',
    label: 'egyptLife'
  },
  {
    name: 'AXA GULF and AXA COOPERATIVE',
    label: 'gulfCooperative'
  },
  {
    name: 'AXA LIFE GERMANY /DE',
    label: 'lebensversicherung'
  },
  {
    name: 'AXA INSURANCE GREECE /GR',
    label: 'greeceInsurance'
  },
  {
    name: 'AXA LIFE INSURANCE GREECE /GR',
    label: 'greeceInsuranceLife'
  },
  {
    name: 'AXA LUXEMBOURG /LU',
    label: 'luxembourg'
  },
  {
    name: 'AXA LIFE LUXEMBOURG',
    label: 'luxembourgLife'
  },
  {
    name: 'AXA ITALY /IT',
    label: 'italy'
  },
  {
    name: 'AXA LIFE ITALY /IT',
    label: 'italyLife'
  },
  {
    name: 'AXA MPS ITALY /IT',
    label: 'italyMps'
  },
  {
    name: 'AXA LIFE MPS ITALY /IT',
    label: 'italyMpsLife'
  },
  {
    name: 'QUIXA ITALY /IT',
    label: 'italyQuixa'
  },
  {
    name: 'AXA FRANCE/FR',
    label: 'france'
  },
  {
    name: 'AXA Life France /FR',
    label: 'franceLife'
  },
  {
    name: 'MUTUELLE ST CHRISTOPHE /FR',
    label: 'mutuelleSaintChristophe'
  },
  {
    name: 'MUTUELLE ST CHRISTOPHE LIFE /FR',
    label: 'mutuelleSaintChristopheLife'
  },
  {
    name: 'AXA France (ex AVANSSUR) /FR DA',
    label: 'franceAvanssur'
  },
  {
    name: 'AXA ASSISTANCE /FR',
    label: 'assistance'
  },
  {
    name: 'AXA BRASIL /BR',
    label: 'brasil'
  },
  {
    name: 'AXA LIFE BRASIL/BR',
    label: 'brasilLife'
  },
  {
    name: 'ROLAND RECHTSSCHUTZ /DE',
    label: 'roland'
  },
  {
    name: 'AGEAS ex AXA Portugal',
    label: 'ageas'
  }
];

export const squadsRowData = [
  {
    name: 'Collective Ownership',
    team: ['AOL']
  },
  {
    name: 'Corporate Universities',
    team: ['AOL']
  },
  {
    name: 'Cultural Assessment',
    team: ['AOL']
  },
  {
    name: 'Digital Learning Contents',
    team: ['AOL']
  },
  {
    name: 'Leadership Effectiveness',
    team: ['AOL']
  },
  {
    name: 'Leading in a hybrid world',
    team: ['AOL']
  },
  {
    name: 'Succession Planning',
    team: ['AOL']
  },
  {
    name: 'Leadership program',
    team: ['AOL']
  },
  {
    name: 'Cultural Transformation',
    team: ['AOL']
  },
  {
    name: 'Cultural Transformation',
    team: ['AOL']
  },
  {
    name: 'Asset management and ESG approaches',
    team: ['AQS']
  },
  {
    name: 'IFRS17 - Getting ready for 2023',
    team: ['AQS']
  },
  {
    name: 'Non vie',
    team: ['AQS']
  },
  {
    name: 'ICS',
    team: ['AQS']
  },
  {
    name: 'KPIs in Insurance industry',
    team: ['AQS']
  },
  {
    name: 'KPIs in Insurance industry',
    team: ['AQS']
  },
  {
    name: 'ORSA : Climate Scenarios',
    team: ['AQS']
  },
  {
    name: 'Non Life (ii)',
    team: ['AQS']
  },
  {
    name: 'ORSA : Climate Scenarios',
    team: ['AQS']
  },
  {
    name: 'Eurocroissance',
    team: ['AQS']
  },
  {
    name: 'Solva UK',
    team: ['AQS']
  },
  {
    name: 'Market risk modelling Offer',
    team: ['AQS']
  },
  {
    name: 'Collar dynamic',
    team: ['AQS']
  },
  {
    name: 'Agile Mobilization',
    team: ['BTR']
  },
  {
    name: 'Business Model Segmentation, Hybrid Op Model',
    team: ['BTR']
  },
  {
    name: 'Moving from PMO to TMO',
    team: ['BTR']
  },
  {
    name: 'The World of Work, Post Pandemic',
    team: ['BTR']
  },
  {
    name: 'Time Out',
    team: ['BTR']
  },
  {
    name: 'Transformation for Good, Adapting Business Strategy to meet our Climate Challenge',
    team: ['BTR']
  },
  {
    name: 'PMO (Phase 2)',
    team: ['BTR']
  },
  {
    name: 'Time Out',
    team: ['BTR']
  },
  {
    name: 'Diagnosis & Assessment (Phase 2)',
    team: ['BTR']
  },
  {
    name: 'Change management',
    team: ['BTR']
  },
  {
    name: 'Agile Methods and Practices',
    team: ['BTR']
  },
  {
    name: 'Business Model Segmentation, Hybrid Op Model',
    team: ['BTR']
  },
  {
    name: 'Agile Mobilization',
    team: ['BTR']
  },
  {
    name: 'Corporate Center Redesign',
    team: ['BTR']
  },
  {
    name: 'AI tools for Business Transformation',
    team: ['BTR']
  },
  {
    name: 'Capacity Management',
    team: ['BTR']
  },
  {
    name: 'IT/OT Convergence',
    team: ['BTR']
  },
  {
    name: 'Corporate Center Redesign',
    team: ['BTR']
  },
  {
    name: 'AI tools for Business Transformation',
    team: ['BTR']
  },
  {
    name: 'Capacity Management',
    team: ['BTR']
  },
  {
    name: 'IT/OT Convergence',
    team: ['BTR']
  },
  {
    name: 'Public Sector - Customer Centrictiy',
    team: ['BTR']
  },
  {
    name: 'PMI E2E Post Merger Integration',
    team: ['BTR']
  },
  {
    name: 'AI x Change Management',
    team: ['BTR']
  },
  {
    name: 'AI for Project Managers',
    team: ['BTR']
  },
  {
    name: 'FRAME',
    team: ['BTR']
  },
  {
    name: 'Rapid Workforce Diagnostic for GenAI-enabled Workforce',
    team: ['BTR']
  },
  {
    name: 'Copilot',
    team: ['BTR']
  },
  {
    name: 'Change Study',
    team: ['BTR']
  },
  {
    name: 'Beyond Banking & Market place',
    team: ['CBA']
  },
  {
    name: 'Consumer Banking & Young Generation',
    team: ['CBA']
  },
  {
    name: 'Crypto App Benchmark',
    team: ['CBA']
  },
  {
    name: 'ESG Retail Banking',
    team: ['CBA']
  },
  {
    name: 'Global Banking App Benchmark',
    team: ['CBA']
  },
  {
    name: 'Global Investment App Benchmark',
    team: ['CBA']
  },
  {
    name: 'Hyperpersonalisation in Banking',
    team: ['CBA']
  },
  {
    name: 'Open Banking',
    team: ['CBA']
  },
  {
    name: 'Personal Banker of tomorrow',
    team: ['CBA']
  },
  {
    name: 'Wealth management within retail banking',
    team: ['CBA']
  },
  {
    name: 'Credentials CBA team ',
    team: ['CBA']
  },
  {
    name: 'Treasury Securities Transparency & Clearing',
    team: ['CBA']
  },
  {
    name: 'Market Trend Analysis',
    team: ['CBA']
  },
  {
    name: 'Rolling stock leasing / Leasing matériel roulant',
    team: ['CBA']
  },
  {
    name: 'Global Banking App Benchmark_v2',
    team: ['CBA']
  },
  {
    name: 'Global Investment App Benchmark_v2',
    team: ['CBA']
  },
  {
    name: 'ESG Retail Banking v2',
    team: ['CBA']
  },
  {
    name: 'Consumer Complaints Management',
    team: ['CBA']
  },
  {
    name: 'Hyperpersonalisation and new ecosystems',
    team: ['CBA']
  },
  {
    name: 'Using AI to enhance Chatbots and Virtual Assistants in Banking',
    team: ['CBA']
  },
  {
    name: 'FinTech: innovation & transformation',
    team: ['CBA']
  },
  {
    name: 'FinTech: innovation & transformation',
    team: ['CBA']
  },
  {
    name: 'Digital Communications in Banking & Insurance',
    team: ['CBA']
  },
  {
    name: 'Factoring',
    team: ['CBA']
  },
  {
    name: 'Car Lease Benchmark',
    team: ['CBA']
  },
  {
    name: 'Global Mobile Banking Benchmark 2024',
    team: ['CBA']
  },
  {
    name: 'Mortgage Credit Journey Benchmark',
    team: ['CBA']
  },
  {
    name: 'Branching strategy',
    team: ['CBA']
  },
  {
    name: 'Prospects and customers journeys in Banking',
    team: ['CBA']
  },
  {
    name: 'Data Monetization in Banking',
    team: ['CBA']
  },
  {
    name: 'Consumer Credit  Customer Journey x Gen AI',
    team: ['CBA']
  },
  {
    name: 'Digital Experience Design',
    team: ['CDE']
  },
  {
    name: 'Service design',
    team: ['CDE']
  },
  {
    name: 'XR collaboration',
    team: ['CDE']
  },
  {
    name: 'Collaborative digital transformation strategy ',
    team: ['CDE']
  },
  {
    name: 'AI uses cases for DPOs',
    team: ['CEP']
  },
  {
    name: 'AI uses cases for DPOs',
    team: ['CEP']
  },
  {
    name: 'Internal Ethics and Compliance Investigations',
    team: ['CEP']
  },
  {
    name: 'Third party risk management',
    team: ['CEP']
  },
  {
    name: 'AI Act Compliance Assessment',
    team: ['CEP']
  },
  {
    name: 'AI Act Documentation Tool',
    team: ['CEP']
  },
  {
    name: 'AI Act Offering',
    team: ['CEP']
  },
  {
    name: 'Contracts Lifecyle and Analytics',
    team: ['CEP']
  },
  {
    name: 'EU Deforestation Regulation',
    team: ['CEP']
  },
  {
    name: 'Big Bloom 2.0',
    team: ['CIB']
  },
  {
    name: 'CIB TOM Use Cases for ESG',
    team: ['CIB']
  },
  {
    name: 'Innovation in capital markets',
    team: ['CIB']
  },
  {
    name: 'Loans Process optimisation',
    team: ['CIB']
  },
  {
    name: 'Market Surveillance',
    team: ['CIB']
  },
  {
    name: 'Onboarding Digitization & Customer Journey',
    team: ['CIB']
  },
  {
    name: 'PMI & Legal entity optimisation',
    team: ['CIB']
  },
  {
    name: 'Regulatory Impacts: Trade Date + 1',
    team: ['CIB']
  },
  {
    name: 'Innovation in Corporate banking/Syndicated loans',
    team: ['CIB']
  },
  {
    name: 'Global Investment App Benchmark_v1',
    team: ['CIB']
  },
  {
    name: 'SEC Equity Market Structure Proposals',
    team: ['CIB']
  },
  {
    name: 'T+1 Accelerated Settlement v1',
    team: ['CIB']
  },
  {
    name: 'T+1 Accelerated Settlement v2',
    team: ['CIB']
  },
  {
    name: 'Trade Date + 1: Impact on Customer Behavior​',
    team: ['CIB']
  },
  {
    name: 'FSOC Prudential Limit Change - CIB',
    team: ['CIB']
  },
  {
    name: 'Clearing',
    team: ['CIB']
  },
  {
    name: 'Generative AI for CIB',
    team: ['CIB']
  },
  {
    name: 'Asset Transfer',
    team: ['CIB']
  },
  {
    name: 'CIB TOM Use Cases for ESG_v2',
    team: ['CIB']
  },
  {
    name: 'Short-Term Funding Markets',
    team: ['CIB']
  },
  {
    name: 'U.S. Treasury Market Central Clearing (SEC)',
    team: ['CIB']
  },
  {
    name: 'Generative AI Survey for CIB',
    team: ['CIB']
  },
  {
    name: 'New US Regulations on Conflicts of Interest in ABS',
    team: ['CIB']
  },
  {
    name: 'SiaGPT Corporate Lending Use Cases',
    team: ['CIB']
  },
  {
    name: 'M&A Transformation',
    team: ['CIB']
  },
  {
    name: 'Client Servicing Hub powered by (Gen)AI & Case Mgt',
    team: ['CIB']
  },
  {
    name: 'CIO as a Service',
    team: ['CIO']
  },
  {
    name: 'Cloud journey',
    team: ['CIO']
  },
  {
    name: 'Fractional CIO',
    team: ['CIO']
  },
  {
    name: 'Hyperautomation',
    team: ['CIO']
  },
  {
    name: 'Sustainable IT',
    team: ['CIO']
  },
  {
    name: 'Next-Generation IT operating model',
    team: ['CIO']
  },
  {
    name: 'Optimize ISDs Energy Bill for Winter 2024',
    team: ['CIO']
  },
  {
    name: 'Reducing IT Complexity',
    team: ['CIO']
  },
  {
    name: 'Enterprise Architecture Maturity Assessment',
    team: ['CIO']
  },
  {
    name: 'Hyperautomation',
    team: ['CIO']
  },
  {
    name: 'Sustainable IT (2)',
    team: ['CIO']
  },
  {
    name: 'Fractional CIO_v2',
    team: ['CIO']
  },
  {
    name: 'ITSM / Service Desk',
    team: ['CIO']
  },
  {
    name: '5-year IT Strategy for Smart Grids & Smart Cities',
    team: ['CIO']
  },
  {
    name: 'Service Catalog',
    team: ['CIO']
  },
  {
    name: 'CIO Advisory 2024 Trends',
    team: ['CIO']
  },
  {
    name: 'CIO Generative AI Survey',
    team: ['CIO']
  },
  {
    name: 'Shadow AI: How to handle it as a CIO?​',
    team: ['CIO']
  },
  {
    name: 'Data Architecture and Governance to Enterprise Architecture',
    team: ['CIO']
  },
  {
    name: 'Technology implementation enablement',
    team: ['CIO']
  },
  {
    name: 'EU AI Act – Implications for CIOs',
    team: ['CIO']
  },
  {
    name: 'AI Ilots urbains de chaleur',
    team: ['CIT']
  },
  {
    name: 'Data Science Gov use cases',
    team: ['CIT']
  },
  {
    name: 'Health strategy of local communities',
    team: ['CIT']
  },
  {
    name: 'Mutualization of functions in public groups / networks',
    team: ['CIT']
  },
  {
    name: "New approaches to citizens' services",
    team: ['CIT']
  },
  {
    name: 'Digital Education',
    team: ['CIT']
  },
  {
    name: 'Regional economic development',
    team: ['CIT']
  },
  {
    name: 'Service Design',
    team: ['CIT']
  },
  {
    name: 'Ecological transition for local communities',
    team: ['CIT']
  },
  {
    name: 'Benchmark governments digital maturity',
    team: ['CIT']
  },
  {
    name: 'Digital education resources for teachers',
    team: ['CIT']
  },
  {
    name: 'Etude France Travail & Entreprises',
    team: ['CIT']
  },
  {
    name: 'Etude France Travail & Entreprises',
    team: ['CIT']
  },
  {
    name: 'Benchmark Study RDI Management Models Worldwide',
    team: ['CIT']
  },
  {
    name: 'Risks and ethics of AI in education',
    team: ['CIT']
  },
  {
    name: 'Gamification of decision making',
    team: ['CIT']
  },
  {
    name: 'Implementation of the home services reform',
    team: ['CIT']
  },
  {
    name: 'Ecologial transition for local communities',
    team: ['CLI']
  },
  {
    name: 'AI x climate',
    team: ['CLI']
  },
  {
    name: 'Biomasses 4 Green Gas',
    team: ['CLI']
  },
  {
    name: 'Biomasses 4 GreenGases',
    team: ['CLI']
  },
  {
    name: 'Carbon capture, utilisation and storage (CCUS)',
    team: ['CLI']
  },
  {
    name: 'Circular Economy',
    team: ['CLI']
  },
  {
    name: 'Climate Design Fiction',
    team: ['CLI']
  },
  {
    name: 'Energy Poverty',
    team: ['CLI']
  },
  {
    name: 'H2 & Industry',
    team: ['CLI']
  },
  {
    name: 'H2 project funding',
    team: ['CLI']
  },
  {
    name: 'Hydrogen Strategies for Regions/Territories Regional H2 Hubs',
    team: ['CLI']
  },
  {
    name: 'Low Carbon & Net Zero (White Label Green)',
    team: ['CLI']
  },
  {
    name: 'Low Carbon & Net Zero Trajectories',
    team: ['CLI']
  },
  {
    name: 'Low Carbon Strategies - Internal Carbon Price',
    team: ['CLI']
  },
  {
    name: 'Low Carbon strategies for collectivities',
    team: ['CLI']
  },
  {
    name: 'Methane Emission Reduction in Energy',
    team: ['CLI']
  },
  {
    name: 'Resilience & Adaptation',
    team: ['CLI']
  },
  {
    name: 'Lifecycle Carbon assessment',
    team: ['CLI']
  },
  {
    name: 'Sustainability Strategy (incl Double materiality assessment, GRI & SDG)',
    team: ['CLI']
  },
  {
    name: 'Climate neutral energy solutions for real estate',
    team: ['CLI']
  },
  {
    name: 'Insetting vs Offsetting Strategies',
    team: ['CLI']
  },
  {
    name: 'Aviation & maritime net zero ambition',
    team: ['CLI']
  },
  {
    name: 'CSRD for companies',
    team: ['CLI']
  },
  {
    name: 'Transition Engineering',
    team: ['CLI']
  },
  {
    name: 'Biogenic CO2 valorization',
    team: ['CLI']
  },
  {
    name: 'TCFD Strategy & Reporting ',
    team: ['CLI']
  },
  {
    name: 'Resilience & Adaptation (2)',
    team: ['CLI']
  },
  {
    name: 'Hydrogen transport & storage infrastrucures',
    team: ['CLI']
  },
  {
    name: 'TCFD Strategy & Reporting ',
    team: ['CLI']
  },
  {
    name: 'Low Carbon & Net Zero Trajectories (2)',
    team: ['CLI']
  },
  {
    name: 'Resilience & Adaptation (2)',
    team: ['CLI']
  },
  {
    name: 'Hydrogen transport & storage infrastrucures',
    team: ['CLI']
  },
  {
    name: 'Assessment of the RFNBO market potential',
    team: ['CLI']
  },
  {
    name: 'Electrolysis and water consumption',
    team: ['CLI']
  },
  {
    name: 'Electrolysis and water consumption',
    team: ['CLI']
  },
  {
    name: 'Methane Emission Reduction in Energy (2)',
    team: ['CLI']
  },
  {
    name: 'Hydrogen',
    team: ['CLI']
  },
  {
    name: 'Energy Transition',
    team: ['CLI']
  },
  {
    name: 'Risks & vulnerability protocol  ',
    team: ['CLI']
  },
  {
    name: 'New Biogas Models',
    team: ['CLI']
  },
  {
    name: 'Heat Pumps',
    team: ['CLI']
  },
  {
    name: 'Dashboard of pre-requisites for CCUS Hubs development',
    team: ['CLI']
  },
  {
    name: 'Stationary batteries state of play',
    team: ['CLI']
  },
  {
    name: 'Study - European industries vulnerability to climate change',
    team: ['CLI']
  },
  {
    name: 'Methane Emission Reduction in Agriculture',
    team: ['CLI']
  },
  {
    name: 'Methane Emission Reduction in Waste',
    team: ['CLI']
  },
  {
    name: 'The essential of SAF',
    team: ['CLI']
  },
  {
    name: 'Space-based Solar Power',
    team: ['CLI']
  },
  {
    name: '2nd e-fuels observatory',
    team: ['CLI']
  },
  {
    name: 'Gen AI for Climate',
    team: ['CLI']
  },
  {
    name: 'Biogas Production Certificate CPB',
    team: ['CLI']
  },
  {
    name: 'Canadien Hydrogen Observatory',
    team: ['CLI']
  },
  {
    name: "Regulations affecting the industry's hydrogen strategy",
    team: ['CLI']
  },
  {
    name: 'Carbon Solution Benchmark',
    team: ['CLI']
  },
  {
    name: 'Floating Wind Energy',
    team: ['CLI']
  },
  {
    name: 'AI-powered distributed solar PV targeting & qualifying',
    team: ['CRP']
  },
  {
    name: 'Data and performance of nuclear power plant',
    team: ['CRP']
  },
  {
    name: 'Hydrogen as offshore energy carrier',
    team: ['CRP']
  },
  {
    name: 'Strategic skills management in the nuclear industry',
    team: ['CRP']
  },
  {
    name: 'Transformation in Power Plant Operations for spare parts',
    team: ['CRP']
  },
  {
    name: 'Virtual Power Plants',
    team: ['CRP']
  },
  {
    name: 'Low Carbon Thermal Generation',
    team: ['CRP']
  },
  {
    name: 'Leveraging OCR and Speech-To-Text for the relevance of power plants O&M activities ',
    team: ['CRP']
  },
  {
    name: 'Anticipating machine failure through AI-powered sensor data analysis',
    team: ['CRP']
  },
  {
    name: 'Participation of local stakeholders into REN projects',
    team: ['CRP']
  },
  {
    name: 'Future of nuclear Gen',
    team: ['CRP']
  },
  {
    name: 'Optimised valorization of renewable electricity produced',
    team: ['CRP']
  },
  {
    name: 'Prioritised repowering projects portfolio',
    team: ['CRP']
  },
  {
    name: 'Digital Twins in Renewable Power Generation ',
    team: ['CRP']
  },
  {
    name: 'Development of an offer on wind repowering',
    team: ['CRP']
  },
  {
    name: 'Future of Nuclear Gen 3',
    team: ['CRP']
  },
  {
    name: 'Coupling stationary batteries and renewable energies',
    team: ['CRP']
  },
  {
    name: 'PPA Alternatives for Tech Infrastructure',
    team: ['CRP']
  },
  {
    name: 'Low-carbon energy solutions for ports enabling dock electrification',
    team: ['CRP']
  },
  {
    name: 'Tech Companies Taskforce',
    team: ['CSP']
  },
  {
    name: 'Telecoms and Communications',
    team: ['CSP']
  },
  {
    name: 'Customer Experience Telecom',
    team: ['CSP']
  },
  {
    name: 'Digital Transformation Assessment',
    team: ['CSP']
  },
  {
    name: 'Gaming',
    team: ['CSP']
  },
  {
    name: 'SVOD : Challenges and strategies',
    team: ['CSP']
  },
  {
    name: 'Telecoms Marketing',
    team: ['CSP']
  },
  {
    name: 'Website Analytics',
    team: ['CSP']
  },
  {
    name: 'Managed Service Center (FPT Strategic Alliance)',
    team: ['CSP']
  },
  {
    name: 'Optimizing Customer Lifecycle Satisfaction',
    team: ['CSP']
  },
  {
    name: 'Gaming Market trends and opportunities for Automotive',
    team: ['CSP']
  },
  {
    name: 'Generative AI for Telecom Operators',
    team: ['CSP']
  },
  {
    name: 'Cyber Compliance',
    team: ['CYB']
  },
  {
    name: 'Cyber Resilience',
    team: ['CYB']
  },
  {
    name: 'Cyber Risk',
    team: ['CYB']
  },
  {
    name: 'Cyber Strategy',
    team: ['CYB']
  },
  {
    name: 'Sia Global CyberLab',
    team: ['CYB']
  },
  {
    name: 'Identity Access Management',
    team: ['CYB']
  },
  {
    name: 'Board Cyber Education and Governance',
    team: ['CYB']
  },
  {
    name: 'Cyber Target Operating Model ',
    team: ['CYB']
  },
  {
    name: 'DPO as a Service for Cybersecuirty',
    team: ['CYB']
  },
  {
    name: 'Digital Operational Resilience Act',
    team: ['CYB']
  },
  {
    name: 'Network and information systems directive NIS2',
    team: ['CYB']
  },
  {
    name: 'OT/ ICS',
    team: ['CYB']
  },
  {
    name: 'CyberMap',
    team: ['CYB']
  },
  {
    name: 'Sia Cyber Trends 2024',
    team: ['CYB']
  },
  {
    name: 'Virtual CISO',
    team: ['CYB']
  },
  {
    name: 'Cyber TPRM&FPRM',
    team: ['CYB']
  },
  {
    name: 'NIST Framework',
    team: ['CYB']
  },
  {
    name: 'Global Cybersecurity Regulatory framework',
    team: ['CYB']
  },
  {
    name: 'Sia Cyber Institute Offering',
    team: ['CYB']
  },
  {
    name: 'Cyber Strategy_v2',
    team: ['CYB']
  },
  {
    name: 'Compliance Center',
    team: ['CYB']
  },
  {
    name: 'Data Breach Management',
    team: ['CYB']
  },
  {
    name: 'Data & AI x Brand',
    team: ['D&C']
  },
  {
    name: 'NextWeb Deployment',
    team: ['D&C']
  },
  {
    name: 'Omnichannel Growth Hacking',
    team: ['D&C']
  },
  {
    name: 'UX Dataviz BI',
    team: ['D&C']
  },
  {
    name: 'Web3 Brand Activation',
    team: ['D&C']
  },
  {
    name: 'Product Management / Product ownership offer',
    team: ['D&C']
  },
  {
    name: 'Product Management / Product ownership offer',
    team: ['D&C']
  },
  {
    name: 'SEM - Digital Performance Marketing',
    team: ['D&C']
  },
  {
    name: 'Accessibility',
    team: ['D&C']
  },
  {
    name: 'Cookie less',
    team: ['D&C']
  },
  {
    name: 'Corporate Branding Platform',
    team: ['D&C']
  },
  {
    name: 'Corporate Branding Platform',
    team: ['D&C']
  },
  {
    name: 'Data Factory Delivery',
    team: ['DFA']
  },
  {
    name: 'Data Maturity',
    team: ['DFA']
  },
  {
    name: 'Data Monetization',
    team: ['DFA']
  },
  {
    name: 'Data Operating Model',
    team: ['DFA']
  },
  {
    name: 'Data Quality',
    team: ['DFA']
  },
  {
    name: 'Data Use Case',
    team: ['DFA']
  },
  {
    name: 'Data Office Booster',
    team: ['DFA']
  },
  {
    name: 'AI Usecases Roadmap',
    team: ['DFA']
  },
  {
    name: 'Business Driven Data Strategy',
    team: ['DFA']
  },
  {
    name: 'Data & AI technology watch',
    team: ['DFA']
  },
  {
    name: 'Data & AI technology watch',
    team: ['DFA']
  },
  {
    name: 'Data Academy',
    team: ['DFA']
  },
  {
    name: 'Data Modeling',
    team: ['DFA']
  },
  {
    name: 'Data Deletion',
    team: ['DFA']
  },
  {
    name: 'Master data management ',
    team: ['DFA']
  },
  {
    name: 'Generative AI for CDO',
    team: ['DFA']
  },
  {
    name: 'AI Transformation for organization',
    team: ['DFA']
  },
  {
    name: 'ROI of Data Transformation',
    team: ['DFA']
  },
  {
    name: 'AI augmented data deletion',
    team: ['DFA']
  },
  {
    name: 'Gen AI CO2 Impact ',
    team: ['DFA']
  },
  {
    name: 'Master Data Management v2',
    team: ['DFA']
  },
  {
    name: 'AI-Powered Data Catalog',
    team: ['DFA']
  },
  {
    name: 'Sia Partners Data and AI Expertise Overview',
    team: ['DFA']
  },
  {
    name: 'Financial Data Solutions',
    team: ['DFA']
  },
  {
    name: 'Business Case for GenAI Project',
    team: ['DFA']
  },
  {
    name: 'Synthetic Data',
    team: ['DFA']
  },
  {
    name: 'AI driven personalization',
    team: ['DMA']
  },
  {
    name: 'AI-driven digital marketing',
    team: ['DMA']
  },
  {
    name: 'Augmented Customer Centricity',
    team: ['DMA']
  },
  {
    name: 'Marketing Mix Modelling',
    team: ['DMA']
  },
  {
    name: 'Analyze the voice of the customer',
    team: ['DMA']
  },
  {
    name: 'Optimized targeting',
    team: ['DMA']
  },
  {
    name: 'Optimized targeting',
    team: ['DMA']
  },
  {
    name: 'Measurement Strategy',
    team: ['DMA']
  },
  {
    name: 'Activation Optimization',
    team: ['DMA']
  },
  {
    name: 'Generative AI for Marketing',
    team: ['DMA']
  },
  {
    name: 'Data Marketing Roadmap',
    team: ['DMA']
  },
  {
    name: 'Data Driven loyalty program',
    team: ['DMA']
  },
  {
    name: 'Unified measurement journey',
    team: ['DMA']
  },
  {
    name: 'Second-Hand pricing',
    team: ['DMA']
  },
  {
    name: 'Customer Data Platform (CDP)',
    team: ['DMA']
  },
  {
    name: 'AI Ethics',
    team: ['DPR']
  },
  {
    name: 'Data Privacy Benchmark',
    team: ['DPR']
  },
  {
    name: 'DORA - Digital Operational Resilience Act',
    team: ['DPR']
  },
  {
    name: 'EU digital & data strategy regulation benchmark',
    team: ['DPR']
  },
  {
    name: 'Cross Border Data Transfer',
    team: ['DPR']
  },
  {
    name: 'Cross Border Data Transfer',
    team: ['DPR']
  },
  {
    name: 'Data retention period definition - Help tool',
    team: ['DPR']
  },
  {
    name: 'Privacy management tools benchmark (European Focus)',
    team: ['DPR']
  },
  {
    name: 'Data retention period definition - Help tool',
    team: ['DPR']
  },
  {
    name: 'Privacy management tools benchmark (European Focus)',
    team: ['DPR']
  },
  {
    name: 'DORA - Digital Operational Resilience Act [2]',
    team: ['DPR']
  },
  {
    name: 'IA Act [2]',
    team: ['DPR']
  },
  {
    name: 'AI systems Mapping',
    team: ['DPR']
  },
  {
    name: 'Data Act decryption',
    team: ['DPR']
  },
  {
    name: 'Anti-Corruption',
    team: ['E&C']
  },
  {
    name: 'ESG data for risk management and reporting',
    team: ['E&C']
  },
  {
    name: 'Legal Operations',
    team: ['E&C']
  },
  {
    name: 'Corporate Sustainability Due diligence',
    team: ['E&C']
  },
  {
    name: 'Art market & Compliance',
    team: ['E&C']
  },
  {
    name: 'US FS Regulatory Watch Newsletter and Tracker',
    team: ['E&C']
  },
  {
    name: 'Conduct Risk',
    team: ['E&C']
  },
  {
    name: 'Risk & Control Statements - SiaGPT',
    team: ['E&C']
  },
  {
    name: 'Energy marketing mix & offering',
    team: ['ERS']
  },
  {
    name: 'Energy Performance Management',
    team: ['ERS']
  },
  {
    name: 'EV For Retailers',
    team: ['ERS']
  },
  {
    name: 'Future pricing models',
    team: ['ERS']
  },
  {
    name: 'Energy Offers & Services',
    team: ['ERS']
  },
  {
    name: 'Energy Efficiency & Energy Contract Management',
    team: ['ERS']
  },
  {
    name: 'PPA - Power Purchase Agreement',
    team: ['ERS']
  },
  {
    name: 'Energy Management for Industrials',
    team: ['ERS']
  },
  {
    name: 'Suppliers 2.0: Target Operating Model',
    team: ['ERS']
  },
  {
    name: 'EV Smart Charging',
    team: ['ERS']
  },
  {
    name: 'EV Model',
    team: ['ERS']
  },
  {
    name: 'Due Diligence EVSE',
    team: ['ERS']
  },
  {
    name: 'Collective Self Consumption_2',
    team: ['ERS']
  },
  {
    name: 'PPA - Power Purchase Agreement_2',
    team: ['ERS']
  },
  {
    name: 'EV as a service for Real Estate',
    team: ['ERS']
  },
  {
    name: 'Rapid Value steering',
    team: ['ERS']
  },
  {
    name: 'Aggregators offers for BtoB energy consumers',
    team: ['ERS']
  },
  {
    name: 'Home Energy Management Systems',
    team: ['ERS']
  },
  {
    name: 'EMIS Market analysis',
    team: ['ERS']
  },
  {
    name: 'PPA Podcast  Gazelec',
    team: ['ERS']
  },
  {
    name: 'Climate Risk Study (led by US)',
    team: ['ESG']
  },
  {
    name: 'ESG Portfolio alignement',
    team: ['ESG']
  },
  {
    name: 'ESG Regulations for Asia',
    team: ['ESG']
  },
  {
    name: 'ESG Strategy & Transformation',
    team: ['ESG']
  },
  {
    name: 'ESG Transformation Asia',
    team: ['ESG']
  },
  {
    name: 'EU ESG Regulations (EBA Pillar 3, SFDR, CSRD...)',
    team: ['ESG']
  },
  {
    name: 'EU Reg squad',
    team: ['ESG']
  },
  {
    name: 'SEC Climate Risk Disclosure Rule',
    team: ['ESG']
  },
  {
    name: 'Enterprise TOM and Client Engagement for Climate Transition',
    team: ['ESG']
  },
  {
    name: 'ESG for Insurance',
    team: ['ESG']
  },
  {
    name: 'CSRD Reporting : Sia Partners x Greenly',
    team: ['ESG']
  },
  {
    name: 'Evolving Disclosure Regulations in the US',
    team: ['ESG']
  },
  {
    name: 'ESG in Asset Management',
    team: ['ESG']
  },
  {
    name: 'ESG Communication S1',
    team: ['ESG']
  },
  {
    name: 'Global ESG reporting standards ISSB  IFRS GRI CSRD  TNFD',
    team: ['ESG']
  },
  {
    name: 'Green Bond',
    team: ['ESG']
  },
  {
    name: 'US Climate Risk Management',
    team: ['ESG']
  },
  {
    name: 'CSRD Marketing and Implementation',
    team: ['ESG']
  },
  {
    name: 'Flexibility services',
    team: ['ETP']
  },
  {
    name: 'LT FC - Solar Impact',
    team: ['ETP']
  },
  {
    name: 'Renewables Forecasting',
    team: ['ETP']
  },
  {
    name: 'Sourcing Pricing',
    team: ['ETP']
  },
  {
    name: '24/7 Hourly Matching',
    team: ['ETP']
  },
  {
    name: 'Dynamic Pricing',
    team: ['ETP']
  },
  {
    name: 'Commodity Trading & Risk Management',
    team: ['ETP']
  },
  {
    name: 'Gen AI use cases in Energy Trading',
    team: ['ETP']
  },
  {
    name: 'Forecasting Capabilities',
    team: ['ETP']
  },
  {
    name: 'HEMS FlexCon',
    team: ['ETP']
  },
  {
    name: 'Energy Market Trainings',
    team: ['ETP']
  },
  {
    name: 'B4 impacts',
    team: ['FRM']
  },
  {
    name: 'Financial Close Automation',
    team: ['FRM']
  },
  {
    name: 'IMM',
    team: ['FRM']
  },
  {
    name: 'IMM - Focus on embedding Climate Risk',
    team: ['FRM']
  },
  {
    name: 'NPL Strategy 2022',
    team: ['FRM']
  },
  {
    name: 'RAF definition for emerging risks',
    team: ['FRM']
  },
  {
    name: 'Reg outlook',
    team: ['FRM']
  },
  {
    name: 'NPL Strategy II',
    team: ['FRM']
  },
  {
    name: 'Banking Observatory',
    team: ['FRM']
  },
  {
    name: 'Asset Liability Management',
    team: ['FRM']
  },
  {
    name: 'Control Framework Optimization',
    team: ['FRM']
  },
  {
    name: 'IREF - Integrated Reporting Framework',
    team: ['FRM']
  },
  {
    name: 'Trends in Finance & Risk Management ',
    team: ['FRM']
  },
  {
    name: 'FSOC Prudential Limit Change - FRM',
    team: ['FRM']
  },
  {
    name: 'US NPL Strategy',
    team: ['FRM']
  },
  {
    name: 'BE-NE Interest Rates Savings accounts ',
    team: ['FRM']
  },
  {
    name: 'Intraday Liquidity Management',
    team: ['FRM']
  },
  {
    name: 'Basel III Endgame',
    team: ['FRM']
  },
  {
    name: 'IFRS9 Forward',
    team: ['FRM']
  },
  {
    name: 'Debt collection strategy',
    team: ['FRM']
  },
  {
    name: 'Banking Observatory 2024',
    team: ['FRM']
  },
  {
    name: 'RAF & Emerging Risk II',
    team: ['FRM']
  },
  {
    name: 'Debt collection strategy_v2',
    team: ['FRM']
  },
  {
    name: 'ALM Governance Benchmark',
    team: ['FRM']
  },
  {
    name: 'ECB SRB Joint Liquidity Template',
    team: ['FRM']
  },
  {
    name: 'Market / CVA Risk',
    team: ['FRM']
  },
  {
    name: 'Stress Testing 2024 – Cyber Resilience​',
    team: ['FRM']
  },
  {
    name: 'Banking Observatory S2 2024',
    team: ['FRM']
  },
  {
    name: 'EBA stress test exercise',
    team: ['FRM']
  },
  {
    name: 'Internal Audit and Risk Squad',
    team: ['FRM']
  },
  {
    name: 'Accelerate your finance transformation with Cloud ERP',
    team: ['FTP']
  },
  {
    name: 'Billing Optimisation',
    team: ['FTP']
  },
  {
    name: 'CFO of tomorrow',
    team: ['FTP']
  },
  {
    name: 'Clinical Trial Services Sourcing',
    team: ['FTP']
  },
  {
    name: 'E-invoicing and dematerialization accelerators',
    team: ['FTP']
  },
  {
    name: 'Finance transformation in Post Covid Era',
    team: ['FTP']
  },
  {
    name: 'Monetize the service economy',
    team: ['FTP']
  },
  {
    name: 'Process Mining for Finance',
    team: ['FTP']
  },
  {
    name: 'Sustainable Packaging',
    team: ['FTP']
  },
  {
    name: 'Working capital optimization',
    team: ['FTP']
  },
  {
    name: 'CSR',
    team: ['FTP']
  },
  {
    name: 'Financial & extra financial reporting',
    team: ['FTP']
  },
  {
    name: 'Data for Finance',
    team: ['FTP']
  },
  {
    name: 'EPM',
    team: ['FTP']
  },
  {
    name: 'Panorama SI',
    team: ['FTP']
  },
  {
    name: 'Digital Finance',
    team: ['FTP']
  },
  {
    name: 'Rapid Finance Diagnostic',
    team: ['FTP']
  },
  {
    name: 'Rapid Finance Diagnostic',
    team: ['FTP']
  },
  {
    name: 'CPO of tomorrow',
    team: ['FTP']
  },
  {
    name: 'Outsourcing of Clinical Trials Services',
    team: ['FTP']
  },
  {
    name: 'CPO of tomorrow',
    team: ['FTP']
  },
  {
    name: 'SiaGPT for Finance',
    team: ['FTP']
  },
  {
    name: 'Digital finance 3 years roadmap',
    team: ['FTP']
  },
  {
    name: 'AI enhanced Spend Analytics',
    team: ['FTP']
  },
  {
    name: 'CFO anti-fraud plan',
    team: ['FTP']
  },
  {
    name: 'IT Financial Management',
    team: ['FTP']
  },
  {
    name: 'Cost optimization - ZBB (previsouly ZBB & cost reduction)',
    team: ['FTP']
  },
  {
    name: 'Generative AI for Procurement',
    team: ['FTP']
  },
  {
    name: 'Lean Agile Procurement',
    team: ['FTP']
  },
  {
    name: 'ERP Rapid Selection',
    team: ['FTP']
  },
  {
    name: 'Smart Close',
    team: ['FTP']
  },
  {
    name: 'Cash Management',
    team: ['FTP']
  },
  {
    name: 'GISS - Geopolitics impacts on Strategic Sourcing',
    team: ['FTP']
  },
  {
    name: 'Innovation Management',
    team: ['GRI']
  },
  {
    name: 'Sustainability & Circularity in Agri / Food Science',
    team: ['GRI']
  },
  {
    name: 'Developing tech-enabled solutions for sustainability challenges',
    team: ['GRI']
  },
  {
    name: 'Ambidextrous Growth',
    team: ['GRI']
  },
  {
    name: 'Venture Building',
    team: ['GRI']
  },
  {
    name: 'Ambidextrous Growth',
    team: ['GRI']
  },
  {
    name: 'Developing tech-enabled solutions for sustainability challenges',
    team: ['GRI']
  },
  {
    name: 'Partner Ecosystems',
    team: ['GRI']
  },
  {
    name: 'Innovation Assessment',
    team: ['GRI']
  },
  {
    name: 'GenAI & Innovation',
    team: ['GRI']
  },
  {
    name: 'Inorganic Growth',
    team: ['GRI']
  },
  {
    name: 'Innovative Business Model for digital solution',
    team: ['GRI']
  },
  {
    name: 'Venture Capital as a Service VCaaS',
    team: ['GRI']
  },
  {
    name: 'Venture Development as a Service VDaaS',
    team: ['GRI']
  },
  {
    name: 'Guild - Crypto currencies',
    team: ['Guild']
  },
  {
    name: 'Guild - Future of Work',
    team: ['Guild']
  },
  {
    name: 'Guild - Decarbonized Transportation / Mobilities',
    team: ['Guild']
  },
  {
    name: 'Guild - [SiaXPerience] Experience by gamification',
    team: ['Guild']
  },
  {
    name: 'Guild - Space',
    team: ['Guild']
  },
  {
    name: 'Guild - Cosmetics',
    team: ['Guild']
  },
  {
    name: 'Guild - Generative AI',
    team: ['Guild']
  },
  {
    name: 'AI for Digital HR',
    team: ['HRP']
  },
  {
    name: 'Attractivity and retention in the war for talent',
    team: ['HRP']
  },
  {
    name: 'Collaboration in an hybrid world',
    team: ['HRP']
  },
  {
    name: 'HR organization global benchmark',
    team: ['HRP']
  },
  {
    name: 'Inclusive Culture & Behaviors',
    team: ['HRP']
  },
  {
    name: 'Labor relations on Climate and ethics',
    team: ['HRP']
  },
  {
    name: 'Local Social dialogue',
    team: ['HRP']
  },
  {
    name: 'Reward Tokenisation',
    team: ['HRP']
  },
  {
    name: 'UIMM',
    team: ['HRP']
  },
  {
    name: 'Employee experience',
    team: ['HRP']
  },
  {
    name: 'Power skills',
    team: ['HRP']
  },
  {
    name: 'HR for scale up',
    team: ['HRP']
  },
  {
    name: 'Companies readiness for climate challenge',
    team: ['HRP']
  },
  {
    name: 'Strategic Workforce Planning + AI',
    team: ['HRP']
  },
  {
    name: 'Gender equality collective agreement benchmark',
    team: ['HRP']
  },
  {
    name: 'HR impacts of generative AI​',
    team: ['HRP']
  },
  {
    name: 'NAO agreement benchmark',
    team: ['HRP']
  },
  {
    name: 'Green job',
    team: ['HRP']
  },
  {
    name: 'Gen Z - a sensational entrance on the labor market',
    team: ['HRP']
  },
  {
    name: 'Individal and collective performance management',
    team: ['HRP']
  },
  {
    name: 'IA for Talent development',
    team: ['HRP']
  },
  {
    name: 'Usure professsionelle',
    team: ['HRP']
  },
  {
    name: 'Generative AI x LnD',
    team: ['HRP']
  },
  {
    name: 'Skills Based Organizations',
    team: ['HRP']
  },
  {
    name: 'Ageing Workforce',
    team: ['HRP']
  },
  {
    name: 'Social Alignment and Political Agility',
    team: ['HRP']
  },
  {
    name: 'Borrower insurance',
    team: ['INS']
  },
  {
    name: 'Claims trends & automation',
    team: ['INS']
  },
  {
    name: 'Cyber insurance products benchmark',
    team: ['INS']
  },
  {
    name: 'Digitization front to back (Overview)',
    team: ['INS']
  },
  {
    name: 'General Agents digitalization study',
    team: ['INS']
  },
  {
    name: 'Green Insurance',
    team: ['INS']
  },
  {
    name: 'Next Level digital customer journeys',
    team: ['INS']
  },
  {
    name: 'Complaints management - New operating model',
    team: ['INS']
  },
  {
    name: 'Insurance strategy for non FS companies',
    team: ['INS']
  },
  {
    name: 'Intelligence in Underwriting',
    team: ['INS']
  },
  {
    name: 'Intelligence in Claims',
    team: ['INS']
  },
  {
    name: 'Intelligence in Agency Operations',
    team: ['INS']
  },
  {
    name: 'Intelligence in Insurer Contact Centers',
    team: ['INS']
  },
  {
    name: 'Contract Review & Insurance Compliance Analysis',
    team: ['INS']
  },
  {
    name: 'Complaints management - New operating model',
    team: ['INS']
  },
  {
    name: 'Insurance of Pros ',
    team: ['INS']
  },
  {
    name: 'Next Level digital customer journeys (edition 2023)',
    team: ['INS']
  },
  {
    name: 'DORA - Risk Appetite & Tolerance',
    team: ['INS']
  },
  {
    name: 'Generative AI use cases in Insurance',
    team: ['INS']
  },
  {
    name: 'Health Insurance',
    team: ['INS']
  },
  {
    name: ' ESG Regulatory & Societal Insights for B2C Insurance clients',
    team: ['INS']
  },
  {
    name: 'P&C digital customer journeys  2024',
    team: ['INS']
  },
  {
    name: 'Gen AI use cases library',
    team: ['INS']
  },
  {
    name: 'Accessibility',
    team: ['INS']
  },
  {
    name: 'Satellite imagery',
    team: ['INS']
  },
  {
    name: 'Bancassurance omnichannel & digital UW for pure prospects',
    team: ['INS']
  },
  {
    name: 'P&C Green insurance for retail',
    team: ['INS']
  },
  {
    name: 'ESG products (both Life & P&C)',
    team: ['INS']
  },
  {
    name: '[FR Only]  ACPR new DDA req.',
    team: ['INS']
  },
  {
    name: 'Life insurance digital customer journeys',
    team: ['INS']
  },
  {
    name: 'Process automation at scale',
    team: ['INS']
  },
  {
    name: 'Data Quality in Insurance',
    team: ['INS']
  },
  {
    name: 'Health and Maturity Assessment',
    team: ['INS']
  },
  {
    name: 'Healthcare Insurance and AI',
    team: ['INS']
  },
  {
    name: 'Service Strategy in Luxury',
    team: ['L&L']
  },
  {
    name: 'Traceability in the Wine & Spirits Supply Chain',
    team: ['L&L']
  },
  {
    name: 'In Store Experience',
    team: ['L&L']
  },
  {
    name: 'MoCRA',
    team: ['L&L']
  },
  {
    name: 'E learning in Beauty and Luxury',
    team: ['L&L']
  },
  {
    name: 'Paris Retail Week and Tech for Retail Learning Expedition ',
    team: ['L&L']
  },
  {
    name: 'Venus',
    team: ['L&L']
  },
  {
    name: 'Ultimate Personalization for an effective Customer Experience in Luxury Cosmetics',
    team: ['L&L']
  },
  {
    name: "Luxury brands' e-commerce best practices",
    team: ['L&L']
  },
  {
    name: 'Deep Review for Healthcare',
    team: ['LFS']
  },
  {
    name: 'E-Health',
    team: ['LFS']
  },
  {
    name: 'Pharma Marketing Excellence',
    team: ['LFS']
  },
  {
    name: 'Smart Quality',
    team: ['LFS']
  },
  {
    name: 'Smart Quality for Clinical Trials',
    team: ['LFS']
  },
  {
    name: 'Operational Equipment Effectiveness',
    team: ['LFS']
  },
  {
    name: 'Healthcare Data Analytics ',
    team: ['LFS']
  },
  {
    name: 'Tokenization',
    team: ['LFS']
  },
  {
    name: 'Implementation of SiaGPT',
    team: ['LFS']
  },
  {
    name: 'Developing Software for Cost of Goods Modeling',
    team: ['LFS']
  },
  {
    name: 'Medical Devices',
    team: ['LFS']
  },
  {
    name: 'PMCSS Prevention and mitigation of clinical supply shortages',
    team: ['LFS']
  },
  {
    name: 'Laboratory Developed Tests LDTs',
    team: ['LFS']
  },
  {
    name: 'Industry 4.0 / Smart Manufacturing',
    team: ['M&D']
  },
  {
    name: 'Operational Excellence 4.0 / Digital LSS Academy',
    team: ['M&D']
  },
  {
    name: 'Digital Twins',
    team: ['M&D']
  },
  {
    name: 'Operational Excellence  Offering',
    team: ['M&D']
  },
  {
    name: 'S&OP',
    team: ['M&D']
  },
  {
    name: 'S and OP w2',
    team: ['M&D']
  },
  {
    name: 'Reverse Logistics transformation',
    team: ['M&D']
  },
  {
    name: 'A and D decarbonation',
    team: ['M&D']
  },
  {
    name: 'Supply Chain Maturity Assessment',
    team: ['M&D']
  },
  {
    name: 'Lean airport',
    team: ['M&D']
  },
  {
    name: 'Mining Data & AI Strategy',
    team: ['M&R']
  },
  {
    name: 'Decarbonisation in Mining',
    team: ['M&R']
  },
  {
    name: 'Copper',
    team: ['M&R']
  },
  {
    name: 'Augmented Sales & Performance',
    team: ['MCX']
  },
  {
    name: 'Marketing & Growth Strategy',
    team: ['MCX']
  },
  {
    name: 'Marketing Transformation',
    team: ['MCX']
  },
  {
    name: 'Next Gen Customer Service',
    team: ['MCX']
  },
  {
    name: 'Seamless & efficient customer experience',
    team: ['MCX']
  },
  {
    name: 'MarTech Data Risk Solutions',
    team: ['MCX']
  },
  {
    name: 'Brand Management',
    team: ['MCX']
  },
  {
    name: 'Brand Management',
    team: ['MCX']
  },
  {
    name: 'CRM tool: selection, design & integration',
    team: ['MCX']
  },
  {
    name: 'Data Monetization',
    team: ['MCX']
  },
  {
    name: 'Knowledge Management for customer service',
    team: ['MCX']
  },
  {
    name: 'Net Zero MVE by Design',
    team: ['MCX']
  },
  {
    name: 'Web3 & Relation mrkt.',
    team: ['MCX']
  },
  {
    name: 'Engagement Marketing',
    team: ['MCX']
  },
  {
    name: 'AI for Field Services (and workforce management)',
    team: ['MCX']
  },
  {
    name: 'Branding ',
    team: ['MDX']
  },
  {
    name: 'Digital Adaptability',
    team: ['MDX']
  },
  {
    name: 'Responsible CX',
    team: ['MDX']
  },
  {
    name: 'Responsible Service',
    team: ['MDX']
  },
  {
    name: 'Next Gen Loyalty',
    team: ['MDX']
  },
  {
    name: 'Smart Customer Service',
    team: ['MDX']
  },
  {
    name: 'PO-PM : Next Level Product Vision & Performance',
    team: ['MDX']
  },
  {
    name: 'ESG Communication Strategy & Implementation',
    team: ['MDX']
  },
  {
    name: 'Performance Marketing Audit',
    team: ['MDX']
  },
  {
    name: 'Headless Wordpress Web Enginereering',
    team: ['MDX']
  },
  {
    name: 'Product Adoption Survey',
    team: ['MDX']
  },
  {
    name: 'Digital Accessibility',
    team: ['MDX']
  },
  {
    name: 'Smart ticketing platform',
    team: ['MDX']
  },
  {
    name: 'Webprompting Experience',
    team: ['MDX']
  },
  {
    name: 'Eco Design of digital services',
    team: ['MDX']
  },
  {
    name: 'Sustainable Digital Marketing ​',
    team: ['MDX']
  },
  {
    name: 'Product Adoption Survey Embodied AI Avatar',
    team: ['MDX']
  },
  {
    name: 'Web3 Loyalty strategy',
    team: ['MDX']
  },
  {
    name: 'CRO Strategy',
    team: ['MDX']
  },
  {
    name: 'DATA chat GPT',
    team: ['MDX']
  },
  {
    name: 'Optimizing marketing effectiveness',
    team: ['MDX']
  },
  {
    name: 'Using AI in consumer research',
    team: ['MDX']
  },
  {
    name: 'Omnichannel Orchestration',
    team: ['MDX']
  },
  {
    name: '3D modelization, operational excellence and customer experience',
    team: ['MDX']
  },
  {
    name: 'Go-to market material for CSRD & Double Materiality',
    team: ['MDX']
  },
  {
    name: 'Gaming & 3D assets',
    team: ['MDX']
  },
  {
    name: 'Gen AI use cases and verticalization for Marketing Sales CX',
    team: ['MDX']
  },
  {
    name: 'Relational signature',
    team: ['MDX']
  },
  {
    name: 'Payment insights',
    team: ['MDX']
  },
  {
    name: 'Augmented consulting - Deep review for CX',
    team: ['MDX']
  },
  {
    name: 'UX DATA chat GPT',
    team: ['MDX']
  },
  {
    name: 'GenAi Communications Training',
    team: ['MDX']
  },
  {
    name: 'Salesforce Center of Excellence',
    team: ['MDX']
  },
  {
    name: 'No-code Low-code sites',
    team: ['MDX']
  },
  {
    name: 'Award Creative Product',
    team: ['MDX']
  },
  {
    name: 'Marketing Insights',
    team: ['MDX']
  },
  {
    name: 'Learning & Development Platform',
    team: ['MET']
  },
  {
    name: '3D Data Visualization & Dynamic NFTs',
    team: ['MET']
  },
  {
    name: 'Embodied AI',
    team: ['MET']
  },
  {
    name: 'Embodied AI_v2',
    team: ['MET']
  },
  {
    name: 'Web3 community engagement',
    team: ['MET ']
  },
  {
    name: 'Metaverse Ethics and Compliance',
    team: ['MET ']
  },
  {
    name: 'Luxury Brand Activation',
    team: ['MET ']
  },
  {
    name: 'Logistic Providers',
    team: ['MOB']
  },
  {
    name: 'Public Transport Authority',
    team: ['MOB']
  },
  {
    name: 'Public Transport Operators',
    team: ['MOB']
  },
  {
    name: 'Transportation Infrastructure & Asset Management',
    team: ['MOB']
  },
  {
    name: 'Vehicle decarbonation ',
    team: ['MOB']
  },
  {
    name: 'Vehicle decarbonation ',
    team: ['MOB']
  },
  {
    name: 'Digital distribution in transportation',
    team: ['MOB']
  },
  {
    name: 'Travellers’ information umbrella offering',
    team: ['MOB']
  },
  {
    name: 'How public policies can enhance modal shift',
    team: ['MOB']
  },
  {
    name: 'How public policies can enhance modal shift',
    team: ['MOB']
  },
  {
    name: 'Umbrella offering for postal operators (MOB)',
    team: ['MOB']
  },
  {
    name: 'Regulatory impacts on urban logistics ',
    team: ['MOB']
  },
  {
    name: 'Regulatory impacts on urban logistics ',
    team: ['MOB']
  },
  {
    name: 'Drivers’ wages panorama ',
    team: ['MOB']
  },
  {
    name: 'Automotive umbrella offering',
    team: ['MOB']
  },
  {
    name: 'New car manufacturers',
    team: ['MOB']
  },
  {
    name: 'Infrastructure and Transport projects : project management know-how and new challenges',
    team: ['MOB']
  },
  {
    name: 'Umbrella Infrastructure managers offering',
    team: ['MOB']
  },
  {
    name: 'Umbrella Infrastructure managers offering (2)',
    team: ['MOB']
  },
  {
    name: 'BioGas Mobility',
    team: ['MOB']
  },
  {
    name: 'EU ETS in land transport',
    team: ['MOB']
  },
  {
    name: 'Use of data in public transportation & micro mobility',
    team: ['MOB']
  },
  {
    name: 'Data monetization for airports',
    team: ['MOB']
  },
  {
    name: 'Decarbonized Urban Logistics Study presentation event',
    team: ['MOB']
  },
  {
    name: 'Logistics for circular economy',
    team: ['MOB']
  },
  {
    name: 'Private equity funds in Mobility & Logistics',
    team: ['MOB']
  },
  {
    name: 'Umbrella offering for postal operators (2)',
    team: ['MOB']
  },
  {
    name: 'Drivers wages panorama (2)',
    team: ['MOB']
  },
  {
    name: 'Drivers wages panorama (2)',
    team: ['MOB']
  },
  {
    name: 'Umbrella Infrastructure managers offering (2)',
    team: ['MOB']
  },
  {
    name: 'BioGas Mobility',
    team: ['MOB']
  },
  {
    name: 'EU ETS in land transport',
    team: ['MOB']
  },
  {
    name: 'Use of data in public transportation & micro mobility',
    team: ['MOB']
  },
  {
    name: 'Data monetization for airports',
    team: ['MOB']
  },
  {
    name: 'Decarbonized Urban Logistics Study presentation event',
    team: ['MOB']
  },
  {
    name: 'Logistics for circular economy',
    team: ['MOB']
  },
  {
    name: 'Private equity funds in Mobility & Logistics',
    team: ['MOB']
  },
  {
    name: 'Data standardisation for logistics/ Logistics data model standards​​',
    team: ['MOB']
  },
  {
    name: 'Retrofit in Transportation (rolling stock, busses, etc) ​',
    team: ['MOB']
  },
  {
    name: 'Automotive  internal meetup session ​',
    team: ['MOB']
  },
  {
    name: 'Rail study + Rail  Campaign (AFRA study)​',
    team: ['MOB']
  },
  {
    name: 'Use of data in public transportation & micro mobility​',
    team: ['MOB']
  },
  {
    name: 'ETS in transport offering​',
    team: ['MOB']
  },
  {
    name: 'Marketing/UX/UI in transport offering​',
    team: ['MOB']
  },
  {
    name: 'Low carbon & net zero strategies campaign​',
    team: ['MOB']
  },
  {
    name: 'AI in transport​',
    team: ['MOB']
  },
  {
    name: 'Zero-Emission Vehicle Strategy for Public Entities​',
    team: ['MOB']
  },
  {
    name: 'Modal shift policies in North America ​',
    team: ['MOB']
  },
  {
    name: 'Airport biometric study, teaser, article & campaign ​',
    team: ['MOB']
  },
  {
    name: 'UAM service development in airports article​',
    team: ['MOB']
  },
  {
    name: 'MOLTS Study',
    team: ['MOB']
  },
  {
    name: 'Zero-Emission Vehicle Strategy for Public Entities​',
    team: ['MOB']
  },
  {
    name: 'AI in transport​',
    team: ['MOB']
  },
  {
    name: 'Innovative Airports Global Offering',
    team: ['MOB']
  },
  {
    name: 'Sea digitalization',
    team: ['MOB']
  },
  {
    name: 'Automonitve competive pricing  analysis campaign',
    team: ['MOB']
  },
  {
    name: 'Data Science & Mobility Offering',
    team: ['MOB']
  },
  {
    name: 'Used electric vehicle market',
    team: ['MOB']
  },
  {
    name: 'Air Freight Decarbonization',
    team: ['MOB']
  },
  {
    name: 'Logistique urbaine  Urban Logistics campaign',
    team: ['MOB']
  },
  {
    name: 'Cybersecurity in Transport',
    team: ['MOB']
  },
  {
    name: 'PTO Benchmark',
    team: ['MOB']
  },
  {
    name: 'GEN AI use cases for Mobility',
    team: ['MOB']
  },
  {
    name: 'E truck fix the basics',
    team: ['MOB']
  },
  {
    name: 'Computer vision for defect detection',
    team: ['MOB']
  },
  {
    name: 'Autonomous train costs and gains',
    team: ['MOB']
  },
  {
    name: 'Conduct Risk',
    team: ['NFR']
  },
  {
    name: 'Crypto Regulatory Compliance',
    team: ['NFR']
  },
  {
    name: 'KYC optimization and mutualization',
    team: ['NFR']
  },
  {
    name: 'Next generation operational risk management framework',
    team: ['NFR']
  },
  {
    name: 'RCSA tactical tool and Operational risks global approach',
    team: ['NFR']
  },
  {
    name: 'Sanctions process optimization',
    team: ['NFR']
  },
  {
    name: 'TPRM Framework & Oversight',
    team: ['NFR']
  },
  {
    name: 'TPRM Framework & Oversight',
    team: ['NFR']
  },
  {
    name: 'Control Framework Optimization',
    team: ['NFR']
  },
  {
    name: 'AML (FR)',
    team: ['NFR']
  },
  {
    name: 'Generative AI for compliance & Risk Management',
    team: ['NFR']
  },
  {
    name: 'Procedure Framework Optimization',
    team: ['NFR']
  },
  {
    name: 'Selecting AML - Filtering & Screening tools ',
    team: ['NFR']
  },
  {
    name: 'US Financial Crimes Advisory',
    team: ['NFR']
  },
  {
    name: 'Risk & Control Statements - SiaGPT',
    team: ['NFR']
  },
  {
    name: 'Gen AI and Automation of Risk Controls',
    team: ['NFR']
  },
  {
    name: 'Recordkeeping',
    team: ['NFR']
  },
  {
    name: 'Regulatory Monitoring',
    team: ['NFR']
  },
  {
    name: 'The Chief Compliance Officer of Tomorrow',
    team: ['NFR']
  },
  {
    name: 'Industrialization of the Compliance function',
    team: ['NFR']
  },
  {
    name: 'Audit',
    team: ['NFR']
  },
  {
    name: 'eFuels',
    team: ['O&G']
  },
  {
    name: 'EPCs shift to Energy Transition projects',
    team: ['O&G']
  },
  {
    name: 'Net zero strategy in Oil&Gas',
    team: ['O&G']
  },
  {
    name: 'Tackling methane emissions in O&G',
    team: ['O&G']
  },
  {
    name: 'The future of gas stations',
    team: ['O&G']
  },
  {
    name: 'The refinery of the future',
    team: ['O&G']
  },
  {
    name: 'ESG / OMS Integration',
    team: ['O&G']
  },
  {
    name: 'Electrifying offshore platforms ',
    team: ['O&G']
  },
  {
    name: 'e-Fuels - Optimal value chain sizing',
    team: ['O&G']
  },
  {
    name: 'The future of gas stations',
    team: ['O&G']
  },
  {
    name: 'Majors Investments',
    team: ['O&G']
  },
  {
    name: 'BNPL',
    team: ['PAY']
  },
  {
    name: 'Digital Wallets',
    team: ['PAY']
  },
  {
    name: 'Frauds on Payments',
    team: ['PAY']
  },
  {
    name: 'Global Offering Payments - Europe',
    team: ['PAY']
  },
  {
    name: 'Green Payments',
    team: ['PAY']
  },
  {
    name: 'Open Payments for Corporate',
    team: ['PAY']
  },
  {
    name: 'Request To Pay',
    team: ['PAY']
  },
  {
    name: 'BNPL B2B',
    team: ['PAY']
  },
  {
    name: 'E-Payments: introduction to Issuing and Acquiring',
    team: ['PAY']
  },
  {
    name: 'PSD3 Readiness',
    team: ['PAY']
  },
  {
    name: 'CBDC',
    team: ['PAY']
  },
  {
    name: 'EPI/ Instant Payments',
    team: ['PAY']
  },
  {
    name: 'PSD3_V2',
    team: ['PAY']
  },
  {
    name: 'Benchmark eCommerce',
    team: ['PAY']
  },
  {
    name: 'Cash Management',
    team: ['PAY']
  },
  {
    name: 'Payments Observatory',
    team: ['PAY']
  },
  {
    name: 'Payments Gen AI',
    team: ['PAY']
  },
  {
    name: 'Customer Experience in Retail',
    team: ['R&EC']
  },
  {
    name: 'e-commerce & omnichannel',
    team: ['R&EC']
  },
  {
    name: 'Fashion e-commerce benchmark',
    team: ['R&EC']
  },
  {
    name: 'Last Mile Logistics',
    team: ['R&EC']
  },
  {
    name: 'Retail at Sia',
    team: ['R&EC']
  },
  {
    name: 'Luxury Retail',
    team: ['R&EC']
  },
  {
    name: 'Apparel ESG',
    team: ['R&EC']
  },
  {
    name: 'RGM Promo',
    team: ['R&EC']
  },
  {
    name: 'S&OP & IBP',
    team: ['R&EC']
  },
  {
    name: 'Retail Q-methodological Brand Audit',
    team: ['R&EC']
  },
  {
    name: 'Thrive with a Marketplace',
    team: ['R&EC']
  },
  {
    name: 'Delivery as a Service',
    team: ['R&EC']
  },
  {
    name: 'Sustainable Retail Journey',
    team: ['R&EC']
  },
  {
    name: 'USP & Brand positioning audit - E- retailers',
    team: ['R&EC']
  },
  {
    name: 'Retail USP & Brand positioning audit',
    team: ['R&EC']
  },
  {
    name: 'Boost online Subscriptions for energy retailers ',
    team: ['R&EC']
  },
  {
    name: 'Ecommerce Audit ',
    team: ['R&EC']
  },
  {
    name: 'Unleashing market value in a​ pre-Acquisition context ',
    team: ['R&EC']
  },
  {
    name: 'Social Commerce',
    team: ['R&EC']
  },
  {
    name: 'Content Strategy',
    team: ['R&EC']
  },
  {
    name: 'Retail Media marketing strategy',
    team: ['R&EC']
  },
  {
    name: 'Employee brand experience in Retail',
    team: ['R&EC']
  },
  {
    name: 'Next gen omnichannel franchise model',
    team: ['R&EC']
  },
  {
    name: 'How can Retailers benefit from generative AI',
    team: ['R&EC']
  },
  {
    name: 'Customer experience in retail',
    team: ['R&EC']
  },
  {
    name: 'Umbrella offering for postal operators (R-EC)',
    team: ['R&EC']
  },
  {
    name: 'Omnichannel Franchise Model',
    team: ['R&EC']
  },
  {
    name: 'Sustainable Brand',
    team: ['R&EC']
  },
  {
    name: 'Direct to Consumer',
    team: ['R&EC']
  },
  {
    name: 'eCommerce & AI',
    team: ['R&EC']
  },
  {
    name: 'Retail Media for brands',
    team: ['R&EC']
  },
  {
    name: 'Mobile App Comparison Food Retail',
    team: ['R-EC']
  },
  {
    name: 'Mobile Benchmark DIY',
    team: ['R-EC']
  },
  {
    name: 'Price Competitiveness',
    team: ['R-EC']
  },
  {
    name: 'e-commerce disrupters, what to learn?',
    team: ['R-EC']
  },
  {
    name: 'E-commerce delivery',
    team: ['R-EC']
  },
  {
    name: 'S&OP & IBP',
    team: ['R-EC']
  },
  {
    name: 'RGM Promo',
    team: ['R-EC']
  },
  {
    name: 'Carbon Calculator',
    team: ['RES']
  },
  {
    name: 'Low Carbon Real Assets - Taxonomy compliance',
    team: ['RES']
  },
  {
    name: 'Next Gen Operation Models - RE as a Service',
    team: ['RES']
  },
  {
    name: 'Real Assets 4.0',
    team: ['RES']
  },
  {
    name: 'Sobriety Plan',
    team: ['RES']
  },
  {
    name: 'Workplace of the future ',
    team: ['RES']
  },
  {
    name: 'Waste Management - Real Estate',
    team: ['RES']
  },
  {
    name: 'Commercial sign',
    team: ['RES']
  },
  {
    name: 'Real Estate Data Repository - MDM ',
    team: ['RES']
  },
  {
    name: 'Commercial sign_v2',
    team: ['RES']
  },
  {
    name: 'ESG & Energy Performance in Real Estate',
    team: ['RES']
  },
  {
    name: 'Building & portfolio Operating System',
    team: ['RES']
  },
  {
    name: 'Analysis on the ageing of the network and way forward',
    team: ['T&D']
  },
  {
    name: 'Asset Management for TSO and DSO',
    team: ['T&D']
  },
  {
    name: 'Battery power storage for networks and manufacturers',
    team: ['T&D']
  },
  {
    name: 'Demand side management',
    team: ['T&D']
  },
  {
    name: 'Flexibility Platform',
    team: ['T&D']
  },
  {
    name: 'Future of TSOs (overall)',
    team: ['T&D']
  },
  {
    name: 'Grid resilience to climate change',
    team: ['T&D']
  },
  {
    name: 'Market Design',
    team: ['T&D']
  },
  {
    name: 'Power system flexibilities for DSOs',
    team: ['T&D']
  },
  {
    name: 'Smart Grids',
    team: ['T&D']
  },
  {
    name: 'Smart Grids roadmap for small and medium-size DSOs',
    team: ['T&D']
  },
  {
    name: 'Smart metering projects set-up',
    team: ['T&D']
  },
  {
    name: 'Smart Meters',
    team: ['T&D']
  },
  {
    name: 'TSO/DSO performance improvement through quantum',
    team: ['T&D']
  },
  {
    name: 'TSO/DSO strategies as Data Services Facilitator',
    team: ['T&D']
  },
  {
    name: 'Adequacy of electricity networks',
    team: ['T&D']
  },
  {
    name: 'Operational excellence related to LV grid connection processes',
    team: ['T&D']
  },
  {
    name: 'Microgrids',
    team: ['T&D']
  },
  {
    name: 'SAIDI improvement',
    team: ['T&D']
  },
  {
    name: 'Stationary battery storage',
    team: ['T&D']
  },
  {
    name: 'Digital Twins for TSOs & DSOs',
    team: ['T&D']
  },
  {
    name: 'Market Design',
    team: ['T&D']
  },
  {
    name: 'Smart metering projects set-up',
    team: ['T&D']
  },
  {
    name: 'Smart Grids roadmap for small and medium-size DSOs',
    team: ['T&D']
  },
  {
    name: 'Control Room of the Future',
    team: ['T&D']
  },
  {
    name: 'Disaster recovery observatory for distribution networks',
    team: ['T&D']
  },
  {
    name: 'Cybersecurity Maturity Assessment for TD Companies',
    team: ['T&D']
  },
  {
    name: 'New capacity remuneration mechanisms',
    team: ['T&D']
  },
  {
    name: 'Airline distribution',
    team: ['T&H']
  },
  {
    name: 'Next gen Travel distribution',
    team: ['T&H']
  },
  {
    name: 'Reboot RM',
    team: ['T&H']
  },
  {
    name: 'RM solutions',
    team: ['T&H']
  },
  {
    name: 'Benchmark of Airlines mobile apps',
    team: ['T&H']
  },
  {
    name: 'Gen AI use cases for Hospitality',
    team: ['T&H']
  },
  {
    name: 'Future of Gas Networks ',
    team: ['UTR']
  },
  {
    name: 'Just Energy Transition',
    team: ['UTR']
  },
  {
    name: 'Just Transition',
    team: ['UTR']
  },
  {
    name: 'DSO Incentive',
    team: ['UTR']
  },
  {
    name: 'Impacts on Nature in Energy and Utility Sectors Data Sharing',
    team: ['UTR']
  },
  {
    name: 'Alternative Investments',
    team: ['WAM']
  },
  {
    name: 'Digital Wealth Management Enablement and Engagement ',
    team: ['WAM']
  },
  {
    name: 'Direct Indexing and Fractional Shares',
    team: ['WAM']
  },
  {
    name: 'Middle Office Outsourcing',
    team: ['WAM']
  },
  {
    name: 'Operations Transformation',
    team: ['WAM']
  },
  {
    name: 'Role of AI in Asset Management',
    team: ['WAM']
  },
  {
    name: 'The Role of AI in Wealth Management',
    team: ['WAM']
  },
  {
    name: 'Virtual (Call Center) Advisory',
    team: ['WAM']
  },
  {
    name: 'Alternative Asset Identifier',
    team: ['WAM']
  },
  {
    name: 'FNZ / SIFMA / Sia Event ',
    team: ['WAM']
  },
  {
    name: 'Alternative Asset Identifier',
    team: ['WAM']
  },
  {
    name: 'Tokenization in AM',
    team: ['WAM']
  },
  {
    name: 'Managed Accounts',
    team: ['WAM']
  },
  {
    name: 'Generative AI for Investment Research',
    team: ['WAM']
  },
  {
    name: 'Financial Planning',
    team: ['WAM']
  },
  {
    name: 'Workplace Wealth',
    team: ['WAM']
  },
  {
    name: 'Offshoring',
    team: ['WAM']
  },
  {
    name: 'AWM E2E Efficiency',
    team: ['WAM']
  },
  {
    name: 'Low Code Applications for Financial Services',
    team: ['WAM']
  },
  {
    name: 'Direct Indexing Competitive Analysis',
    team: ['WAM']
  },
  {
    name: 'Future of Asset Management Operations',
    team: ['WAM']
  },
  {
    name: 'Modern Wealth Platforms ',
    team: ['WAM']
  },
  {
    name: 'Gamification in Service Operations',
    team: ['WAM']
  },
  {
    name: 'Industrial Symbiosis development - Cycle 2',
    team: ['WAS']
  },
  {
    name: 'Industrial Waste Performance',
    team: ['WAS']
  },
  {
    name: 'Industrial Waste Performance - Cycle 2',
    team: ['WAS']
  },
  {
    name: 'Nature Positive Transition',
    team: ['WAS']
  },
  {
    name: 'Nature Positive Transition -  Cycle 2',
    team: ['WAS']
  },
  {
    name: 'Nature & Biodiversity in Finance',
    team: ['WAS']
  },
  {
    name: 'Nature & Biodiversity',
    team: ['WAS']
  },
  {
    name: 'Chemical recycling of plastic',
    team: ['WAS']
  },
  {
    name: 'Circular economy of Water',
    team: ['WAS']
  },
  {
    name: 'Circular economy of Water 2',
    team: ['WAS']
  },
  {
    name: 'Artificial Intelligence and Wastewater Spill prediction',
    team: ['WAT']
  },
  {
    name: 'New Markets for Water',
    team: ['WAT']
  },
  {
    name: 'Data Mesh',
    team: ['DFA']
  },
  {
    name: 'Industrial decarbonization - marginal abatement costs',
    team: ['CLI']
  },
  {
    name: 'Next Gen Loyalty 2',
    team: ['MDX']
  },
  {
    name: 'Energy Providers Customer Area Benchmark',
    team: ['ERS']
  },
  {
    name: 'UAM Observatory',
    team: ['MOB']
  },
  {
    name: 'Maximize the Brand of IT',
    team: ['CIO']
  },
  {
    name: 'Scarcity of critical equipment for electricity networks',
    team: ['T&D']
  },
  {
    name: 'Logistique urbaine  Urban Logistics Operational solutions year 2',
    team: ['MOB']
  },
  {
    name: 'New capacity remuneration mechanisms',
    team: ['T&D']
  },
  {
    name: 'Edge computing for DSOs',
    team: ['T&D']
  },
  {
    name: 'DSOs digital customer experience',
    team: ['T&D']
  },
  {
    name: 'Loyalty in Retail',
    team: ['R&EC']
  },
  {
    name: 'Mastering Software Licensing A Comprehensive Review Initiative',
    team: ['CIO']
  },
  {
    name: 'GEN AI EVALUATION TOOL FOR CIOs CTOs',
    team: ['CIO']
  },
  {
    name: 'AI for Industrial Operations',
    team: ['M&D']
  },
  {
    name: 'Industrial Maintenance transformation trends',
    team: ['M&D']
  },
  {
    name: 'Industrial Maintenance transformation trends',
    team: ['M&D']
  },
  {
    name: 'Grid connection of EV charging points Ramping up plan for DSOs',
    team: ['T&D']
  },
  {
    name: '2nd International efuels observatory',
    team: ['CLI']
  },
  {
    name: 'COMPUTER VISION FOR REAL ESTATE',
    team: ['RES']
  },
  {
    name: 'Chief Sustainability Officer CSO',
    team: ['M&R']
  },
  {
    name: 'Security and Law enforcement Policing ',
    team: ['CIT']
  },
  {
    name: 'Impacts and outcomes of commercial real Estate on banks',
    team: ['FRM']
  },
  {
    name: 'Digital Roadmap',
    team: ['CIO']
  },
  {
    name: 'Biomethane Purchase Agreement',
    team: ['CLI']
  },
  {
    name: 'Offshoring Incentives in Banking',
    team: ['CBA']
  }
];

export const teamsRowData = [
  {
    name: 'Actuarial & Quantitative Services',
    code: 'AQS'
  },
  {
    name: 'Art of Leadership',
    code: 'AOL'
  },
  {
    name: 'Business Transformation',
    code: 'BTR'
  },
  {
    name: 'CIB',
    code: 'CIB'
  },
  {
    name: 'CIO Advisory',
    code: 'CIO'
  },
  {
    name: 'Compliance, Ethics & Privacy',
    code: 'CEP'
  },
  {
    name: 'Citizen Services',
    code: 'CIT'
  },
  {
    name: 'Climate Analysis Center',
    code: 'CLI'
  },
  {
    name: 'Collaborative Design',
    code: 'CDE'
  },
  {
    name: 'Communication, Software & Platforms',
    code: 'CSP'
  },
  {
    name: 'Consumer Banking',
    code: 'CBA'
  },
  {
    name: 'Conventional & Renewable Power Gen',
    code: 'CRP'
  },
  {
    name: 'Cybersecurity',
    code: 'CYB'
  },
  {
    name: 'Data Factory',
    code: 'DFA'
  },
  {
    name: 'Data Marketing',
    code: 'DMA'
  },
  {
    name: 'Data Protection',
    code: 'DPR'
  },
  {
    name: 'Digital & Comms',
    code: 'D&C'
  },
  {
    name: 'Energy Retail & Services',
    code: 'ERS'
  },
  {
    name: 'Energy Trading & Portfolio Management',
    code: 'ETP'
  },
  {
    name: 'Ethics & Compliance',
    code: 'E&C'
  },
  {
    name: 'Finance & Risk Management',
    code: 'FRM'
  },
  {
    name: 'Finance Transformation & Procurement',
    code: 'FTP'
  },
  {
    name: 'Growth & Innovation',
    code: 'GRI'
  },
  {
    name: 'HR Performance & Services',
    code: 'HRP'
  },
  {
    name: 'Insurance',
    code: 'INS'
  },
  {
    name: 'Life sciences & Healthcare',
    code: 'LFS'
  },
  {
    name: 'Manufacturing & Defense',
    code: 'M&D'
  },
  {
    name: 'Marketing & Customer Experience',
    code: 'MCX'
  },
  {
    name: 'Metaverse',
    code: 'MET'
  },
  {
    name: 'Mobility',
    code: 'MOB'
  },
  {
    name: 'Non-Financial Risks',
    code: 'NFR'
  },
  {
    name: 'Oil & Gas upstream – downstream',
    code: 'O&G'
  },
  {
    name: 'Payments',
    code: 'PAY'
  },
  {
    name: 'Real Estate',
    code: 'RES'
  },
  {
    name: 'Retail & E-Commerce',
    code: 'R&EC'
  },
  {
    name: 'Sustainable Finance / ESG',
    code: 'ESG'
  },
  {
    name: 'Tourism & Hospitality',
    code: 'T&H'
  },
  {
    name: 'Transmission & Distribution',
    code: 'T&D'
  },
  {
    name: 'Utilities Regulation',
    code: 'UTR'
  },
  {
    name: 'Waste Management & Circular Economy',
    code: 'WAS'
  },
  {
    name: 'Water Management',
    code: 'WAT'
  },
  {
    name: 'Wealth & Asset Management',
    code: 'WAM'
  },
  {
    name: 'Guild',
    code: 'Guild'
  },
  {
    name: 'Marketing, Digital & Customer experience',
    code: 'MDX'
  },
  {
    name: 'Mining & Resources',
    code: 'M&R'
  },
  {
    name: 'Luxury & Lifestyle',
    code: 'L&L'
  }
];
