import type { CustomHeaderProps } from 'ag-grid-react';
import { KeyRound } from 'lucide-react';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@components/ui/tooltip';

export interface PrimaryKeyHeaderProps extends CustomHeaderProps {}

export const PrimaryKeyHeaderComponent = (props: PrimaryKeyHeaderProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center gap-2.5">
            <span>{props.displayName}</span>
            <KeyRound size={16} color="#635CF8" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Primary key</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
