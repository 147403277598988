export class PasswordProtectedError extends Error {}

export class TooManyRequestsError extends Error {}

export class RowDeletionError extends Error {
  private numberOfUndeletedRows: number;

  constructor(numberOfUndeletedRows: number) {
    super(`${numberOfUndeletedRows} row(s) not deleted. Please try again.`);
    this.numberOfUndeletedRows = numberOfUndeletedRows;
  }

  get undeletedRows(): number {
    return this.numberOfUndeletedRows;
  }
}
