import { Link, LinkProps } from 'react-router-dom';

const OverviewContainer = ({ children, className = '' }) => (
  <div className={`w-full max-w-[1150px] flex flex-col gap-14 ${className}`}>
    {children}
  </div>
);
const OverviewTypeTitle = ({ children, className = '' }) => (
  <h2 className={`text-[1.2rem] font-semibold mb-4 ${className}`}>
    {children}
  </h2>
);

const OverviewItem = ({ children, className = '' }) => (
  <section className={` ${className}`}>{children}</section>
);

interface WorkflowCardProps extends LinkProps {
  $isLoading: boolean;
}

const WorkflowCardContainer = ({
  children,
  $isLoading,
  ...props
}: WorkflowCardProps) => (
  <Link
    className={`
      relative flex flex-col box-border rounded-lg 
      bg-white
      transition-shadow duration-150 
       min-h-[240px] 
      ${$isLoading ? 'cursor-auto' : 'cursor-pointer'} 
      overflow-hidden
      tracking-normal
      hover:shadow-verailHover
      border border-solid
      border-gray-linear`}
    {...props}
  >
    {children}
  </Link>
);
const WorkflowPreview = ({ children, className = '' }) => (
  <div className={`w-full h-[60%] ${className}`}>{children}</div>
);
const WorkflowInfos = ({ children, className = '' }) => (
  <div className={`flex justify-between  gap-3 p-3 ${className}`}>
    {children}
  </div>
);
const WorkflowName = ({ children, className = '' }) => (
  <span
    className={`h-6 text-sm font-medium text-zinc-950 truncate ${className}`}
  >
    {children}
  </span>
);
const WorkflowStatus = ({ children, className = '' }) => (
  <span className={`h-6 ${className}`}>{children}</span>
);

const LastEditInfo = ({ children, className = '' }) => (
  <span className={`text-sm text-zinc-500 ${className}`}>{children}</span>
);

const Side = ({ children, className = '' }) => (
  <div className={`flex flex-col  gap-[0.4rem]  ${className}`}>{children}</div>
);

export {
  OverviewContainer,
  OverviewTypeTitle,
  WorkflowCardContainer,
  OverviewItem,
  WorkflowName,
  WorkflowStatus,
  WorkflowPreview,
  WorkflowInfos,
  Side,
  LastEditInfo
};
