import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';

import useProcess from '@hooks/useProcess';

import { CircularProgress } from '@mui/joy';

const Settings = () => {
  const process = useProcess();
  if (!process)
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );

  return (
    <div className="w-full h-full flex flex-col">
      <section className="h-full">
        <Outlet />
      </section>
    </div>
  );
};

export default observer(Settings);
